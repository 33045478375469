import { createAction } from "redux-act";


export const REQOcrLog = createAction("REQUEST_OCR_LOG");
export const RESOcrLog = createAction("RECEIVE_OCR_LOG");
export const FailOcrLog = createAction("FAILED_OCR_LOG");
export const CleanUpOcrLog= createAction("CLEANUP_OCR_LOG");

export const REQManualEmail = createAction("REQUEST_MANUAL_EMAIL");
export const RESManualEmail = createAction("RECEIVE_MANUAL_EMAIL");
export const FailManualEmail = createAction("FAILED_MANUAL_EMAIL");

