import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import {
  setFullImgUrl,
  requestUntHldDetailDocuments,
  requestUntHldDetailDocumentApprove,
  requestUntHldDetailDocumentReject,
  requestUntHldDetailDocumentUndo,
  FETCH_REGISTERED_IMAGE_REQUEST,
  FETCH_COUNTERSERVICE_IMAGE_REQUEST,
  FETCH_UPLOAD_FILE_TO_S3_REQUEST,
  FETCH_SAVE_TO_DB_REQUEST,
  savingtodb,
  uploadingToS3,
} from "./redux/actions";
import Images from "../../utils/styles/images";
import "./style.scss";
import axios from "axios";
import Loader from "../../components/Loader";
import ManualUpload from "./ManualUpload";
import BasicInput from "../../components/MainComponent/Input/basic-input";
import BasicCheckbox from "../../components/MainComponent/Checkbox/basic-checkbox";
import BasicRadio from "../../components/MainComponent/Radio/basic-radio";
import BasicDropdown from "../../components/MainComponent/Dropdown/basic-dropdown";
import BasicTextArea from "../../components/MainComponent/Textarea/basic-textarea";
/*
    Document Status Codes map to the following
    PENDING = 1,
    APPROVED = 2,
    REJECTED = 3,
    IN_REVIEW = 4,
*/

export const documentTypeNameMapping = () => {
  return {
    1: "IDFront.jpeg",
    2: "IDBack.jpeg",
    3: "AVATAR",
    4: "Bankbook.jpeg",
    5: "HouseholdBill.jpeg",
    6: "ACKNOWLEDGEMENT_SIGNATURE",
    7: "PassportFront.jpeg",
    9: "ndid.jpeg",
    10: "ID card_7-11.jpeg",
  };
};

function ClientDocuments(props) {
  const {
    requestUntHldDetailDocuments,
    requestingClientDocuments,
    documentDetails,
    requestDocumentApprove,
    requestDocumentReject,
    requestDocumentUndo,
    requestingDocumentApproval,
    requestingDocumentRejection,
    requestingDocumentUndo,
    clientDetail,
    customerChannel,
    avatar,
    FETCH_REGISTERED_IMAGE_REQUEST,
    registeredImage,
    regisImageDate,
    FETCH_COUNTERSERVICE_IMAGE_REQUEST,
    counterCard,
    counterIdentify,
    counterDate,
    FETCH_UPLOAD_FILE_TO_S3_REQUEST,
    s3posturl,
    s3formdata,
    FETCH_SAVE_TO_DB_REQUEST,
    savingtodb,
    uploadingToS3,
    requestingOcr,
    ocrResponse,
  } = props;
  const [currentDocument, setCurrentDocument] = useState(0);
  const [zoomDocument, setZoomDocument] = useState(false);
  const [currentScrollYPosition, setCurrentScrollYPosition] = useState(0);
  const [rotateLevel, setRotateLevel] = useState(0);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [tempFile, setTempFile] = useState(null);
  const [rejectInput, setRejectInput] = useState({
    remark_th: "",
    remark_en: "",
  });
  const [currentRemark, setCurrentRemark] = useState("");
  const [userAccountDetail, setUserAccountDetail] = useState(null);

  const [dropdownRejectedBankReason, setDropdownRejectedBankReason] =
    useState("no_option");

  const dropdownOptions = [
    {
      value: "no_option",
      label_th: "เลือกสาเหตุ ",
      label_en: "Select a reason",
      selected: true,
    },
    {
      value: "option1",
      label_th: "เอกสารที่อัปโหลดไม่ชัดเจนหรือคุณภาพต่ำ ",
      label_en:
        "Document is not clear or low quality",
      selected: true,
    },
    {
      value: "option2",
      label_th: "ข้อมูลในเอกสารไม่ตรงกับข้อมูลที่ส่งมา",
      label_en:
        "Information on document does not match with submitted information",
    },
    {
      value: "option3",
      label_th: "ข้อมูลในเอกสารไม่ถูกต้อง ไม่สมบูรณ์ หรือขาดข้อมูลที่จำเป็น เช่น ชื่อจริง นามสกุล เลขบัญชี และชื่อสาขา",
      label_en: "Information is invalid or incomplete",
    },
    {
      value: "option4",
      label_th: "ประเภทเอกสารไม่รองรับ (รองรับเฉพาะหน้าสมุดบัญชี, หน้าสมุดบัญชีออนไลน์ และรายงานการเคลื่อนไหวทางบัญชีเท่านั้น)",
      label_en:
        "Document type is not supported",
    },
    // {
    //   value: "option5",
    //   label_th:
    //     "ประเภทเอกสารไม่รองรับ (เช่น สมุดบัญชีธนาคาร, สมุดบัญชีออนไลน์, รายการเดินบัญชี)",
    //   label_en:
    //     "The document type is not supported (only book bank, online book bank, and statement are supported)",
    // },
    {
      value: "other_option",
      label_th: "อื่นๆ",
      label_en: "Other",
    },
  ];

  const [currentIDCardRemark, setCurrentIDCardRemark] = useState("");
  const [dropdownRejectedIDCardReason, setDropdownRejectedIDCardReason] =
  useState("no_option");

  const dropdownIDCardOptions = [
    {
      value: "no_option",
      label_th: "เลือกสาเหตุ ",
      label_en: "Select a reason",
      selected: true,
    },
    {
      value: "option1",
      label_th: "รูปบัตรประชาชนที่อัปโหลดไม่ชัดเจนหรือคุณภาพต่ำ",
      label_en:
        "National ID picture is not clear or low quality",
      selected: true,
    },
    {
      value: "option2",
      label_th: "ข้อมูลในรูปบัตรประชาชนไม่ตรงกับข้อมูลที่กรอกมา",
      label_en:
        "Information on the national ID picture does not match with submitted information",
    },
    {
      value: "other_option",
      label_th: "อื่นๆ",
      label_en: "Other",
    },
  ];

  useEffect(() => {
    documentDetails?.map((document) => {
      if (document.typeCode === "4") {
        const remarkTh = document.remark_th ?? "";
        const remarkEn = document.remark_en ?? "";
        setCurrentRemark(remarkTh + `(${remarkEn})`);
      }
    });
  }, [documentDetails]);
  
  useEffect(() => {
    documentDetails?.map((document) => {
      if(document.typeCode === "1") {
        const remarkIDCardTh = document.remark_th ?? "";
        const remarkIDCardEn = document.remark_en ?? "";
        setCurrentIDCardRemark(remarkIDCardTh + `(${remarkIDCardEn})`);
      }
    });
  }, [documentDetails]);

  useEffect(() => {
    const userDetail = JSON.parse(
      localStorage.getItem("currentCustomerUntHldDetail")
    );
    setUserAccountDetail(userDetail);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await requestUntHldDetailDocuments();
      FETCH_REGISTERED_IMAGE_REQUEST();
      FETCH_COUNTERSERVICE_IMAGE_REQUEST();
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("[dropdownRejectedBankReason]", dropdownRejectedBankReason);
  }, [dropdownRejectedBankReason]);

  useEffect(() => {
    const createBlobFromImageURL = async () => {
      const imgURL = await imageURL();
      console.log("imgurl", imgURL);
      if (imgURL.startsWith("data:image/")) {
        const arr = imgURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        const u8arr = new Uint8Array(bstr.length);
        for (let i = 0; i < bstr.length; i++) {
          u8arr[i] = bstr.charCodeAt(i);
        }
        const blob = new Blob([u8arr], { type: mime });
        setTempFile(blob);
        console.log("Tempt file set");
      }
    };

    if (registeredImage || counterIdentify) {
      createBlobFromImageURL();
    }
  }, []);

  useEffect(() => {
    if (requestingClientDocuments && !hasLoadedOnce) {
      setHasLoadedOnce(true);
    }

    if (!requestingClientDocuments && hasLoadedOnce && documentDetails) {
      const typeCode9Document = documentDetails.find(
        (doc) => doc.typeCode === "9"
      );
      if (
        typeCode9Document === undefined &&
        tempFile &&
        s3posturl &&
        s3formdata
      ) {
        console.log("No Type 9, beginning auto upload");
        uploadFile("selfie", tempFile);
      } else {
      }
    }
  }, [requestingClientDocuments]);

  const getNdidImage = async () => {
    await documentDetails;

    if (!documentDetails || !Array.isArray(documentDetails)) {
      console.error("documentDetails is not valid:", documentDetails);
      return ""; // คืนค่าว่างหาก documentDetails ไม่ถูกต้อง
    }

    const typeCode9Document = documentDetails.find(
      (doc) => doc.typeCode === "9"
    );

    let tImg = null;
    let RegisURL = "";

    if (registeredImage && !typeCode9Document) {
      tImg = registeredImage;
      RegisURL = "data:image/png;base64," + tImg;
    }

    return RegisURL;
  };

  const getCounterServiceImage = async () => {
    await documentDetails;
    const typeCode9Document = await documentDetails.find(
      (doc) => doc.typeCode === "9"
    );
    let tImg = null;
    let RegisURL = "";

    if (counterIdentify && typeCode9Document === undefined) {
      tImg = counterIdentify;
      RegisURL = "data:image/png;base64," + tImg;
    }
    return RegisURL;
  };

  const imageURL = () => {
    if (customerChannel === "NDID") {
      console.log("Account is NDID");
      return getNdidImage();
    } else if (customerChannel === "COUNTER_SERVICE") {
      console.log("Account is Counter_Service");
      return getCounterServiceImage();
    }
    return "";
  };

  // const tempFile = useMemo(async() => {
  //   const imgURL = await imageURL();
  //   if (imgURL.startsWith("data:image/")) {
  //     const arr = imgURL.split(',');
  //     const mime = arr[0].match(/:(.*?);/)[1];
  //     const bstr = atob(arr[1]);
  //     const u8arr = new Uint8Array(bstr.length);
  //     for (let i = 0; i < bstr.length; i++) {
  //       u8arr[i] = bstr.charCodeAt(i);
  //     }
  //     return new Blob([u8arr], { type: mime });
  //   }
  //   return null;
  // }, []);

  const handleUploadComplete = () => {
    console.log("Upload complete in ManualUpload");
    requestUntHldDetailDocuments();
  };

  useScrollPosition(({ currPos }) => {
    // Get the height difference after a user scrolls
    setCurrentScrollYPosition(currPos.y);
  });

  const rotateValue = useMemo(() => {
    return rotateLevel * 90;
  }, [rotateLevel]);

  const setRotateLevelLeft = () => {
    if (rotateLevel === 0) {
      setRotateLevel(3);
    } else {
      setRotateLevel(rotateLevel - 1);
    }
  };

  const setRotateLevelRight = () => {
    if (rotateLevel === 3) {
      setRotateLevel(0);
    } else {
      setRotateLevel(rotateLevel + 1);
    }
  };
  const SETxDocType9 = (pocustomerChannel) => {
    let tType9 = "";
    switch (pocustomerChannel) {
      case "NDID":
        tType9 = "ndid photo ";
        break;
      case "COUNTER_SERVICE":
        tType9 = "counter service photo";
        break;
      default:
        tType9 = "Selfie With ID";
    }
    return tType9;
  };

  const uploadFile = async (position, file) => {
    try {
      console.group("[document.js]");
      FETCH_UPLOAD_FILE_TO_S3_REQUEST(position);
      const formData = new FormData();
      if (s3formdata) {
        console.log("[S3 FORM DATA]", s3formdata);
        s3formdata.forEach(([key, value]) => {
          formData.append(key, value);
        });
      }

      formData.append("file", file, `identification-${position}.jpg`);
      console.log("[CURRENT POSITION] :", position);
      console.log("[S3 POSTURL] :", s3posturl);
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${pair[1]}`);
      }
      await fetch(s3posturl, {
        method: "POST",
        body: formData,
        mode: "no-cors",
      });

      console.log("File uploaded successfully");
      FETCH_SAVE_TO_DB_REQUEST(position);
      requestUntHldDetailDocuments();
      console.groupEnd("[document.js]");
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  let documentNameMapping = {
    1: "Thai ID",
    2: "Thai ID",
    3: "Profile Picture",
    4: "Bankbook",
    5: "ThaiHousehold Registration / Credit Card StatementID",
    6: "Signature",
    7: "Passport",
    // 9: "Selfie With ID",
    9: SETxDocType9(customerChannel),
    10: "ID card_7-11",
  };

  // function processDocumentDetails(documentDetails) {
  //   if (documentDetails) {
  //     const typeCode9Document = documentDetails.find(doc => doc.typeCode === "9");

  //   if (!typeCode9Document) {
  //     const newDocument = {
  //       typeCode: "9",
  //       lastModifiedAt: getLastModified(),
  //       url: getNdidImage(),
  //     };

  //     return [...documentDetails, newDocument];
  //   }
  //   return documentDetails;
  //   }

  //   return documentDetails;
  // }

  const documentStatus =
    documentDetails != null
      ? documentDetails[currentDocument] &&
        documentDetails[currentDocument].statusCode
        ? documentDetails[currentDocument].statusCode
        : null
      : null;

  const documentImage =
    documentDetails != null
      ? documentDetails[currentDocument] && documentDetails[currentDocument].url
        ? documentDetails[currentDocument].url
        : null
      : null;

  const documentTypeCode =
    documentDetails != null
      ? documentDetails[currentDocument] &&
        documentDetails[currentDocument].typeCode
        ? documentDetails[currentDocument].typeCode
        : null
      : null;
  const documentTypeName = documentTypeCode
    ? documentNameMapping[documentTypeCode]
    : "";
  const documentFilename = documentTypeCode
    ? documentTypeNameMapping()[documentTypeCode]
    : "Default.jpeg";
  const documentDate =
    documentDetails != null
      ? documentDetails[currentDocument] &&
        documentDetails[currentDocument].lastModifiedAt
        ? moment(documentDetails[currentDocument].lastModifiedAt).format(
            "DD/MM/YYYY"
          )
        : null
      : null;

  const ndidDocument = documentDetails;
  const ndidImage =
    documentDetails != null
      ? documentDetails
          .filter((data) => data.typeCode === 9)
          .map((data) => {
            return data.url;
          })
      : "";
  const documentNdidImage = ndidImage;
  const ndidDocumentTypeCode =
    documentDetails != null
      ? documentDetails
          .filter((data) => data.typeCode === 9)
          .map((data) => {
            return data.typeCode;
          })
      : "";
  const ndidDocumentTypeName = ndidDocumentTypeCode
    ? documentNameMapping[ndidDocumentTypeCode]
    : "";
  const ndidDocumentFileName = ndidDocumentTypeCode
    ? documentTypeNameMapping()[ndidDocumentTypeCode]
    : "Default.jpeg";

  const isUserCanApprove = clientDetail
    ? clientDetail.canApprove === true
      ? true
      : false
    : false;


  const handleRejectPressed = () => {
    requestDocumentReject({
      documentID:
        documentDetails != null ? documentDetails[currentDocument].uuid : "",
      avatar: avatar,
      unitholderId: userAccountDetail?.unitholderid ?? "unknown",
      remark_en: rejectInput.remark_en,
      remark_th: rejectInput.remark_th,
    });
  };

  const handleApprovePressed = () => {
    setDropdownRejectedIDCardReason('no_option');
    setDropdownRejectedBankReason('no_option');
    requestDocumentApprove({
      documentID: documentDetails[currentDocument].uuid,
      avatar: avatar,
      unitholderId: userAccountDetail?.unitholderid ?? "unknown",
      remark_en: '',
      remark_th: '',
    });
  };

  const handleUndoPressed = async () => {
    setDropdownRejectedIDCardReason('no_option');
    setDropdownRejectedBankReason('no_option');
    try {
      await requestDocumentUndo({
        documentID: documentDetails[currentDocument].uuid,
        avatar: avatar,
      });
    } catch (error) {
      console.error("ERROR FROM [handleUndoPressed]", error.message);
    }
  };

  const handleMagicDocumentFormScroll = () => {
    // This basically allows the document form to float into whatever empty space up top when user scrolls down
    if (currentScrollYPosition < 0) {
      if (currentScrollYPosition < -249) {
        return 100 - 250;
      } else {
        return 100 + currentScrollYPosition;
      }
    } else {
      return 100;
    }
  };

  const handleImageClick = () => {
    localStorage.setItem("fullImgUrl", documentImage);
    window.open(
      `/FullDocImage?imgUrl=${encodeURIComponent(
        documentImage
      )}&rotate=${rotateLevel}`
    );
  };

  useEffect(() => {
    console.log("reject input", rejectInput);
  }, [rejectInput]);

  useEffect(() => {
    if(documentTypeCode === "1") {
      setDropdownRejectedBankReason('no_option');
    }
    if(documentTypeCode === "4") {
      setDropdownRejectedIDCardReason('no_option');
    }
  },[documentTypeCode])

  // * CONDITION
  const isBookBank = documentTypeCode === "4";
  const isIDCard = documentTypeCode === "1";
  const isPendingStatus = documentStatus === "1";
  const isApprovedStatus = documentStatus === "2";
  const isRejectedStatus = documentStatus === "3";
  const isPendingClientStatus = clientDetail.statusCode === "1";
  const isApprovedClientStatus = clientDetail.statusCode === "2";
  const isRejectedClientStatus = clientDetail.statusCode === "3";
  const isDropdownRejectIsNoOption = dropdownRejectedBankReason === "no_option";
  const isDropdownRejectIdCardIsNoOption = dropdownRejectedIDCardReason === "no_option";
  const isDropdownOthersNotfilled = dropdownRejectedBankReason === "other_option" && (rejectInput.remark_en === "" || rejectInput.remark_th === "");
  const isIDCardDropdownOthersNotfilled = dropdownRejectedIDCardReason === "other_option" && (rejectInput.remark_en === "" || rejectInput.remark_th === "");
  const isNotBankbookAndApproved = isApprovedStatus && !isBookBank;
  const isNotBankbookAndRejected = isRejectedStatus && !isBookBank;
  const isNoOptionAndisBankBook = isDropdownRejectIsNoOption && isBookBank;

  const isNotIDCardAndApproved = isApprovedStatus && !isIDCard;
  const isNotIDCardAndRejected = isRejectedStatus && !isIDCard;
  const isNoOptionAndisIDCard = isDropdownRejectIdCardIsNoOption && isIDCard;

  const disableDropdownForApprovedAndRejectedIdCard = isIDCard && isApprovedStatus || isIDCard && isRejectedStatus;
  const disableDropdownForApprovedAndRejectedBookBank = isBookBank && isApprovedStatus || isBookBank && isRejectedStatus;

  const isAllDisabledConditionForRejected =
    isNotBankbookAndApproved ||
    isNotBankbookAndRejected ||
    isNoOptionAndisBankBook ||
    isDropdownOthersNotfilled ||
    !isPendingStatus

  const isAllDisabledConditionForRejectedIdCard =
  isNotIDCardAndApproved ||
  isNotIDCardAndRejected ||
  isNoOptionAndisIDCard ||
  isIDCardDropdownOthersNotfilled ||
    !isPendingStatus
  
  const isAllDisabledConditionForApproved =
    isNotBankbookAndApproved || isNotBankbookAndRejected || !isPendingStatus;
  const isApprovedPersonAndBookBank = isApprovedClientStatus && isBookBank;
  const isRejectedPersonAndBookBank = isRejectedClientStatus && isBookBank;
  const isApprovedPersonAndBookBank_OR_isRejectedPersonAndBookBank =
    isApprovedPersonAndBookBank || isRejectedPersonAndBookBank;

  const isApprovedPersonAndIdCard = isApprovedClientStatus && isIDCard;
  const isRejectedPersonAndIdCard = isRejectedClientStatus && isIDCard;
  const isApprovedPersonAndIdCard_OR_isRejectedPersonAndBookBank = isApprovedPersonAndIdCard || isRejectedPersonAndIdCard;
  return customerChannel === "NDID" && customerChannel === "COUNTER_SERVICE" ? (
    <div
      className="client-detail-profile-document-floater"
      style={{ marginTop: handleMagicDocumentFormScroll() }}
    >
      {requestingDocumentApproval ||
      requestingDocumentRejection ||
      requestingDocumentUndo ||
      requestingClientDocuments ||
      savingtodb ||
      uploadingToS3 ? (
        <div className="document-circular-loader">
          <CircularProgress className="document-status-progress" />
          {requestingDocumentApproval ? (
            <p className="document-status-text-progress">
              Requesting document approval...
            </p>
          ) : null}
          {requestingDocumentRejection ? (
            <p className="document-status-text-progress">
              Requesting document rejection...
            </p>
          ) : null}
          {requestingDocumentUndo ? (
            <p className="document-status-text-progress">
              Requesting document status reset...
            </p>
          ) : null}
          {requestingClientDocuments ? (
            <p className="document-status-text-progress">
              Refreshing document list...
            </p>
          ) : null}
          {savingtodb ? (
            <p className="document-status-text-progress">
              Saving NDID image...
            </p>
          ) : null}
          {uploadingToS3 ? (
            <p className="document-status-text-progress">Uploading file...</p>
          ) : null}
          {requestingOcr ? (
            <p className="document-status-text-progress">Checking file...</p>
          ) : null}
          {requestingOcr ? (
            <p className="document-status-text-progress">Checking file...</p>
          ) : null}
        </div>
      ) : null}

      <p className="client-detail-profile-document-header">DOCUMENT</p>
      <div className="client-detail-profile-document-container">
        <img
          src={Images.documents.zoom}
          alt="magnifer-icon"
          onClick={() => setZoomDocument(!zoomDocument)}
          className="client-detail-document-zoom-button"
        />
        <div className="client-detail-profile-document-magnifier">
          {documentImage ? (
            <img
              src={documentNdidImage}
              alt="document-NDID"
              className={
                zoomDocument
                  ? "client-detail-document-image-zoomed"
                  : "client-detail-document-image"
              }
            />
          ) : null}
        </div>
        <div className="client-detail-profile-document-switcher">
          <img
            src={Images.documents.leftArrow}
            alt="left-arrow-icon"
            className={"client-detail-documents-left-arrow-disabled"}
          />
          <div className="client-detail-image-switcher-container">
            {ndidDocument
              .filter((document) => document.typeCode === 4)
              .map((document, index) => (
                <img
                  onClick={() => setCurrentDocument(index)}
                  key={`mini-document-image-${index}`}
                  src={document.url}
                  alt={document.typeCode}
                  className={
                    index === currentDocument
                      ? "client-detail-documents-mini"
                      : "client-detail-documents-mini-unselected"
                  }
                />
              ))}
          </div>
          <img
            src={Images.documents.rightArrow}
            alt="right-arrow-icon"
            className={"client-detail-documents-right-arrow-disabled"}
          />
        </div>
      </div>
      <div className="client-detail-profile-document-details">
        <div className="client-detail-profile-document-details-headers">
          <p className="client-detail-profile-document-details-headers-doctype">
            DOC. TYPE
          </p>
          <p className="client-detail-profile-document-details-headers-filename">
            FILE NAME
          </p>
          <p className="client-detail-profile-document-details-headers-date">
            DATE
          </p>
        </div>
        <div className="client-detail-profile-document-details-values">
          <p className="client-detail-profile-document-details-values-doctype">
            {ndidDocumentTypeName}
          </p>
          <p className="client-detail-profile-document-details-values-filename">
            {ndidDocumentFileName}
          </p>
          <p className="client-detail-profile-document-details-values-date">
            {documentDate}
          </p>
        </div>
      </div>
      <div className="client-detail-profile-document-buttons-container">
        {documentStatus === "1" ? (
          <Fragment>
            <img
              src={Images.statusIcon.pending}
              alt="pending-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-pending">Pending</p>
          </Fragment>
        ) : null}
        {documentStatus === "2" ? (
          <Fragment>
            <img
              src={Images.statusIcon.approved}
              alt="approved-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-approved">Approved</p>
          </Fragment>
        ) : null}
        {documentStatus === "3" ? (
          <Fragment>
            <img
              src={Images.statusIcon.rejected}
              alt="rejected-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-rejected">Rejected</p>
          </Fragment>
        ) : null}
        {documentStatus === "4" ? (
          <Fragment>
            <img
              src={Images.statusIcon.pending}
              alt="in-review-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-pending">In-Review</p>
          </Fragment>
        ) : null}

        {documentStatus === "2" || documentStatus === "3" ? (
          <img
            src={Images.documents.undo}
            alt="refresh-icon"
            onClick={() => handleUndoPressed()}
            className={
              clientDetail.statusCode === "2"
                ? "client-detail-profile-document-undo-disabled"
                : "client-detail-profile-document-undo"
            }
          />
        ) : (
          <div className="space-between" />
        )}
        <button
          className={
            documentStatus === "2" || documentStatus === "3"
              ? "client-detail-profile-document-reject-disabled"
              : "client-detail-profile-document-reject"
          }
          disabled={
            documentStatus === "2" ||
            documentStatus === "3" ||
            clientDetail.statusCode === "2"
          }
          onClick={() => handleRejectPressed()}
        >
          REJECT
        </button>
        <button
          className={
            documentStatus === "2" ||
            documentStatus === "3" ||
            clientDetail.statusCode === "3" ||
            clientDetail.statusCode === "2" ||
            !isUserCanApprove
              ? "client-detail-profile-document-approve-disabled"
              : "client-detail-profile-document-approve"
          }
          disabled={
            documentStatus === "2" ||
            documentStatus === "3" ||
            !isUserCanApprove
          }
          onClick={() => handleApprovePressed()}
        >
          APPROVE
        </button>
      </div>
    </div>
  ) : (
    <div
      className="client-detail-profile-document-floater"
      style={{
        marginTop: handleMagicDocumentFormScroll(),
      }}
    >
      {requestingDocumentApproval ||
      requestingDocumentRejection ||
      requestingDocumentUndo ||
      requestingClientDocuments ||
      savingtodb ||
      uploadingToS3 ? (
        <div className="document-circular-loader">
          <CircularProgress className="document-status-progress" />
          {requestingDocumentApproval ? (
            <p className="document-status-text-progress">
              Requesting document approval...
            </p>
          ) : null}
          {requestingDocumentRejection ? (
            <p className="document-status-text-progress">
              Requesting document rejection...
            </p>
          ) : null}
          {requestingDocumentUndo ? (
            <p className="document-status-text-progress">
              Requesting document status reset...
            </p>
          ) : null}
          {requestingClientDocuments ? (
            <p className="document-status-text-progress">
              Refreshing document list...
            </p>
          ) : null}
          {savingtodb ? (
            <p className="document-status-text-progress">
              Saving NDID image...
            </p>
          ) : null}
          {uploadingToS3 ? (
            <p className="document-status-text-progress">Uploading file...</p>
          ) : null}
        </div>
      ) : null}

      <p className="client-detail-profile-document-header">DOCUMENT</p>
      <div className="client-detail-profile-document-container">
        <img
          src={Images.documents.zoom}
          alt="magnifer-icon"
          onClick={() => setZoomDocument(!zoomDocument)}
          className="client-detail-document-zoom-button"
        />
        <img
          src={Images.documents.rotateRight}
          alt="magnifer-icon"
          className="client-detail-document-rotate-right-button"
          onClick={() => setRotateLevelRight()}
        />
        <img
          src={Images.documents.rotateLeft}
          alt="magnifer-icon"
          className="client-detail-document-rotate-left-button"
          onClick={() => setRotateLevelLeft()}
        />
        <div
          className="client-detail-profile-document-magnifier"
          onClick={handleImageClick}
        >
          {documentImage ? (
            <img
              src={documentImage}
              className={
                zoomDocument
                  ? "client-detail-document-image-zoomed"
                  : "client-detail-document-image"
              }
              style={{
                transform: `rotate(${rotateValue}deg)`,
                zIndex: -1,
              }}
            />
          ) : null}
          {/* {registeredImage ? (
              <img
              src={getNdidImage()}
              className={
                zoomDocument
                  ? "client-detail-document-image-zoomed"
                  : "client-detail-document-image"
              }
              style={{ 
                transform: `rotate(${rotateValue}deg)`, 
                zIndex: -1,
              }}
              />
            )  : null
            } */}
        </div>
        <div className="client-detail-profile-document-switcher">
          <img
            src={Images.documents.leftArrow}
            alt="left-arrow-icon"
            className={
              currentDocument === 0
                ? "client-detail-documents-left-arrow-disabled "
                : "client-detail-documents-left-arrow"
            }
            onClick={() =>
              setCurrentDocument(
                currentDocument - 1 >= 0 ? currentDocument - 1 : currentDocument
              )
            }
          />
          <div className="client-detail-image-switcher-container">
            {documentDetails != null
              ? documentDetails?.map((document, index) => (
                  <img
                    onClick={() => setCurrentDocument(index)}
                    key={`mini-document-image-${index}`}
                    src={document.url}
                    alt={
                      document.typeCode == 1
                        ? "Thai ID"
                        : document.typeCode == 4
                        ? "Bankbook "
                        : document.typeCode == 9
                        ? "NDID Photo"
                        : ""
                    }
                    className={
                      index === currentDocument
                        ? "client-detail-documents-mini"
                        : "client-detail-documents-mini-unselected"
                    }
                  />
                ))
              : ""}
          </div>
          <img
            src={Images.documents.rightArrow}
            alt="right-arrow-icon"
            className={
              documentDetails != null
                ? currentDocument === documentDetails.length - 1
                  ? "client-detail-documents-right-arrow-disabled"
                  : "client-detail-documents-right-arrow"
                : "client-detail-documents-right-arrow-disabled"
            }
            onClick={() =>
              setCurrentDocument(
                documentDetails != null
                  ? currentDocument + 1 < documentDetails.length
                    ? currentDocument + 1
                    : currentDocument
                  : currentDocument
              )
            }
          />
        </div>
      </div>
      {/* ส่วนการแสดงผล 3 ส่วน Doc Type , File Name , Date */}
      <div className="client-detail-profile-document-details">
        <div className="client-detail-profile-document-details-headers">
          <p className="client-detail-profile-document-details-headers-doctype">
            DOC. TYPE
          </p>
          <p className="client-detail-profile-document-details-headers-filename">
            FILE NAME
          </p>
          <p className="client-detail-profile-document-details-headers-date">
            DATE
          </p>
        </div>
        <div className="client-detail-profile-document-details-values">
          <p className="client-detail-profile-document-details-values-doctype">
            {documentTypeName}
          </p>
          <p className="client-detail-profile-document-details-values-filename">
            {documentFilename}
          </p>
          <p className="client-detail-profile-document-details-values-date">
            {documentDate}
          </p>
        </div>
      </div>

      {/* Reject Reason */}
      {documentTypeCode === "4" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            marginLeft: "2.75rem",
            marginRight: "2rem",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <span
              style={{
                display: currentRemark ? "block" : "none",
              }}
            >
              {documentStatus === "3" ? `Current Reason : ${currentRemark}` : ''}
            </span>
            <div>
              <BasicDropdown
                // headers={"เลือกสาเหตุที่ต้องการ"}
                value={dropdownRejectedBankReason}
                options={dropdownOptions}
                disabled={disableDropdownForApprovedAndRejectedBookBank}
                label=""
                onChange={(value) => {
                  setDropdownRejectedBankReason(value);
                  if (value !== "other_option") {
                    setRejectInput({
                      remark_th:
                        dropdownOptions.find((item) => item.value === value)
                          ?.label_th || "",
                      remark_en:
                        dropdownOptions.find((item) => item.value === value)
                          ?.label_en || "",
                    });
                  } else {
                    setRejectInput({
                      remark_th: "",
                      remark_en: "",
                    });
                  }
                }}
              />
            </div>

            {/* REMARK */}
            {dropdownRejectedBankReason === "other_option" && (
              <>
                {/* REMARK (ภาษาไทย) */}
                <div
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <BasicTextArea
                    placeholder="โปรดระบุสาเหตุ (ภาษาไทย)"
                    required
                    value={rejectInput.remark_th}
                    onChange={(event) => {
                      setRejectInput((prev) => ({
                        ...prev,
                        remark_th: event.target.value,
                      }));
                    }}
                  />
                </div>

                {/* REMARK (English) */}
                <div
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <BasicTextArea
                    placeholder="Please specify the reason (English)"
                    value={rejectInput.remark_en}
                    onChange={(event) => {
                      setRejectInput((prev) => ({
                        ...prev,
                        remark_en: event.target.value,
                      }));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}

      {documentTypeCode === "1" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            marginLeft: "2.75rem",
            marginRight: "2rem",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <span
              style={{
                display: currentIDCardRemark ? "block" : "none",
              }}
            >
              {documentStatus === "3" ? `Current Reason : ${currentIDCardRemark}` : ''}
            </span>
            <div>
              <BasicDropdown
                // headers={"เลือกสาเหตุที่ต้องการ"}
                options={dropdownIDCardOptions}
                value={dropdownRejectedIDCardReason}
                disabled={disableDropdownForApprovedAndRejectedIdCard}
                label=""
                onChange={(value) => {
                  setDropdownRejectedIDCardReason(value);
                  if (value !== "other_option") {
                    setRejectInput({
                      remark_th:
                        dropdownIDCardOptions.find((item) => item.value === value)
                          ?.label_th || "",
                      remark_en:
                        dropdownIDCardOptions.find((item) => item.value === value)
                          ?.label_en || "",
                    });
                  } else {
                    setRejectInput({
                      remark_th: "",
                      remark_en: "",
                    });
                  }
                }}
              />
            </div>

            {/* REMARK */}
            {dropdownRejectedIDCardReason === "other_option" && (
              <>
                {/* REMARK (ภาษาไทย) */}
                <div
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <BasicTextArea
                    placeholder="โปรดระบุสาเหตุ (ภาษาไทย)"
                    required
                    value={rejectInput.remark_th}
                    onChange={(event) => {
                      setRejectInput((prev) => ({
                        ...prev,
                        remark_th: event.target.value,
                      }));
                    }}
                  />
                </div>

                {/* REMARK (English) */}
                <div
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <BasicTextArea
                    placeholder="Please specify the reason (English)"
                    value={rejectInput.remark_en}
                    onChange={(event) => {
                      setRejectInput((prev) => ({
                        ...prev,
                        remark_en: event.target.value,
                      }));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}

      <div className="client-detail-profile-document-buttons-container">
        {documentStatus === "1" ? (
          <Fragment>
            <img
              src={Images.statusIcon.pending}
              alt="pending-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-pending">Pending</p>
          </Fragment>
        ) : null}
        {documentStatus === "2" ? (
          <Fragment>
            <img
              src={Images.statusIcon.approved}
              alt="approved-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-approved">Approved</p>
          </Fragment>
        ) : null}
        {documentStatus === "3" ? (
          <Fragment>
            <img
              src={Images.statusIcon.rejected}
              alt="rejected-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-rejected">Rejected</p>
          </Fragment>
        ) : null}
        {documentStatus === "4" ? (
          <Fragment>
            <img
              src={Images.statusIcon.pending}
              alt="in-review-icon"
              className="client-detail-profile-status-icon"
            />
            <p className="client-detail-document-pending">In-Review</p>
          </Fragment>
        ) : null}

        {documentStatus === "2" || documentStatus === "3" ? (
          <img
            src={Images.documents.undo}
            alt="refresh-icon"
            onClick={() => {
              if (
                isApprovedPersonAndBookBank_OR_isRejectedPersonAndBookBank ||
                isApprovedPersonAndIdCard_OR_isRejectedPersonAndBookBank ||
                isPendingClientStatus
              ) {
                handleUndoPressed();
              }
            }}
            className={
              isApprovedPersonAndBookBank_OR_isRejectedPersonAndBookBank ||
              isApprovedPersonAndIdCard_OR_isRejectedPersonAndBookBank ||
              isPendingClientStatus
                ? "client-detail-profile-document-undo"
                : "client-detail-profile-document-undo-disabled"
            }
          />
        ) : (
          <div className="space-between" />
        )}
        {documentTypeCode === "4" ? 
          <button
          className={
            isAllDisabledConditionForRejected
              ? "client-detail-profile-document-reject-disabled"
              : "client-detail-profile-document-reject"
          }
          disabled={isAllDisabledConditionForRejected}
          onClick={() => handleRejectPressed()}
        >
          REJECT
        </button>
        : documentTypeCode === "1" ?     
          <button
            className={
              isAllDisabledConditionForRejectedIdCard
                ? "client-detail-profile-document-reject-disabled"
                : "client-detail-profile-document-reject"
            }
            disabled={isAllDisabledConditionForRejectedIdCard}
            onClick={() => handleRejectPressed()}
          >
            REJECT
          </button>
        :
        <button
          className={
            isAllDisabledConditionForRejected
              ? "client-detail-profile-document-reject-disabled"
              : "client-detail-profile-document-reject"
          }
          disabled={isAllDisabledConditionForRejected}
          onClick={() => handleRejectPressed()}
        >
          REJECT
        </button>
      }

        <button
          className={
            isAllDisabledConditionForApproved || !isUserCanApprove
              ? "client-detail-profile-document-approve-disabled"
              : "client-detail-profile-document-approve"
          }
          disabled={isAllDisabledConditionForApproved || !isUserCanApprove}
          onClick={() => handleApprovePressed()}
        >
          APPROVE
        </button>
      </div>

      <div className="client-detail-profile-document-buttons-container">
        <ManualUpload
          onUploadComplete={handleUploadComplete}
          documentTypeCode={documentTypeCode}
          documentStatusCode={documentStatus}
        />
        {/* <button
            className={"client-detail-profile-document-refresh"}
            onClick={() => ManualUpload()}
          >
            MANUAL UPLOAD
          </button> */}
      </div>
    </div>
  );
}

const withConnect = connect(
  (state) => ({
    ...state.untHldDetailReducer,
  }),
  {
    setFullImgUrl,
    requestUntHldDetailDocuments,
    requestDocumentApprove: requestUntHldDetailDocumentApprove,
    requestDocumentReject: requestUntHldDetailDocumentReject,
    requestDocumentUndo: requestUntHldDetailDocumentUndo,
    FETCH_REGISTERED_IMAGE_REQUEST,
    FETCH_COUNTERSERVICE_IMAGE_REQUEST,
    FETCH_UPLOAD_FILE_TO_S3_REQUEST,
    FETCH_SAVE_TO_DB_REQUEST,
  }
);

export default compose(withConnect, withRouter)(ClientDocuments);
