import { SELECTED_OBJECT } from "./actions";

const initialState = {
  selectedObject: "",
  MemPageAll:""
};

// start test pass
// fix lint
export default function breadCrumbsReducer(state = initialState, action = {}) {
  const actionMappings = {
    // use all action on containers
    [SELECTED_OBJECT]: () => ({
      ...state,
      selectedObject: action.selectedObject
    }),
    "SET_CURRENT_CUSTOMER_UNTHLD_DETAIL": () => ({ ...state, MemPageAll: action }),
    "RECEIVE_UNTHLD_SRCH": () => ({ ...state, MemPageAll: action }),
    "SET_CURRENT_CUSTOMER": () => ({ ...state, MemPageAll: action }),
    "[3] MEM_UsrActDETAIL": () => ({ ...state, MemPageAll: action }),
    "MEM_TAGDETAIL_EDIT": () => ({ ...state, MemPageAll: action }),
    "MEM_FUNDRECRISKDETAIL_EDIT": () => ({ ...state, MemPageAll: action }),
    "MEM_FUNDRECCODIDETAIL_EDIT": () => ({ ...state, MemPageAll: action }),
    "SET_MODE_FUND_DETAILS": () => ({ ...state, MemPageAll: action }),
    "MEM_CONFDDETAIL_EDIT": () => ({ ...state, MemPageAll: action }),
    "MEM_REGISDEVICEDETAIL": () => ({ ...state, MemPageAll: action }),
    "MEM_ACTIDETAIL": () => ({ ...state, MemPageAll: action }),
    "MEM_RESGIS_LOG_DETAIL": () => ({ ...state, MemPageAll: action }),
    "MEM_CONSENTVS_LIST": () => ({ ...state, MemPageAll: action }),
    "MEM_CONSENTVS_DETAIL": () => ({ ...state, MemPageAll: action }),
    "REQUEST_ANALYTIC_LIST": () => ({ ...state, MemPageAll: action }),
    "RECEIVE_EASYINV_DETAIL": () => ({ ...state, MemPageAll: action }),
    "RECEIVE_UNTHLD_DETAIL": () => ({ ...state, MemPageAll: action }),
    "MEM_NDID_LOG_DETAIL": () => ({ ...state, MemPageAll: action }),
    "SET_CURRENT_CUSTOMER_UNTHLD_DETAIL_AUTO": () => ({ ...state, MemPageAll: action }),
  };

  const reducerFunction = actionMappings[action.type] || (() => state);

  return reducerFunction();
}
// end test