import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { WarningModal, ErrorModal, AlertModal } from "../../components/Modal";
import Images from "../../utils/styles/images";
import PersonalDetailsForm from "./form/PersonalDetailsHookForm";
import ClientDocuments, { documentTypeNameMapping } from "./documents";
import ClientFunds from "./funds";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";

import {
  requestUnthldDetailProfile,
  requestUntHldDetailDocuments,
  requestUntHldDetailAccountLockedStatus,
  requestUntHldDetailAccountUnlock,
  requestUntHldDetailApprove,
  requestUntHldDetailReject,
  requestUntHldDetailCancelStatus,
  clearUntHldDetailErrorMessage,
  REQUsrActSrch,
  FETCH_TABLE_HISTORY_LOG_PAGINATION,
} from "./redux/actions";
import "./style.scss";
import BasicButton from "../../components/MainComponent/Button/basic-button";
import TableHistoryLogPagination from "./table/pagination/table-history-log";
import { HeadCellsHistoryLog } from "./list_of_columns";
import axios from "axios";

const accStatus = {
  PENDING: "1",
  APPROVED: "2",
  REJECTED: "3",
  IN_REVIEW: "4",
  CREATING: "5",
  JUST_CONVERT_FROM_EXISTING: "6",
};

function AutoApproveDetail(props) {
  const {
    history,
    requestUnthldDetailProfile,
    avatar,
    profilePic,
    requestingClientProfile,

    documentDetails,
    document9Selfie,
    // --setCurrentCustomer--
    customerUuid,
    unitholderId,
    clientDetail,
    UserName,
    PageCurrent,
    UISearch,
    unitholderMethod,

    // -- TABLE HISTORY LOG --
    TABLE_HISTORY_LOG_DATA,
    TABLE_HISTORY_LOG_REQUESTING,
    TABLE_HISTORY_LOG_DATA_PAGINATION,
    FETCH_TABLE_HISTORY_LOG_PAGINATION,

    requestUntHldDetailAccountLockedStatus,
    requestUntHldDetailAccountUnlock,
    requestingUnlock,
    requestingLockedStatus,
    isLockedStatus,

    requestUntHldDetailApprove,
    requestingClientApproval,

    requestUntHldDetailReject,
    requestUntHldDetailCancelStatus,

    requestingClientRejection,
    requestingCancelStatus,
    errorMessage,
    clearUntHldDetailErrorMessage,
    // -----UserAccount----
    REQUsrActSrch,
    REQStrUsrActSrch,
    // -----UnitHolder----
    unitholderIdFrmApprove,
    // requesting ndid
    uploadingToS3,
    savingtodb,
  } = props;

  // console.log("UntHldDetail props:",props);
  const [currentTab, setCurrentTab] = useState("PROFILE");
  const [oShwApvCliAlr, SETxShwApvCliAlr] = useState(false);
  const [disableApprove, setDisableApprove] = useState(false);
  const [actionType, setActionType] = useState("");
  const [oAlertType, SETxAlertType] = useState({
    AlertType: "",
    ShowAlert: false,
    Message: "",
  });

  const [CountModalErrorApprove, setCountModalErrorApprove] = useState(true);
  const [isPageReload, setIsPageReload] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);
  const [userAccountDetail, setUserAccountDetail] = useState(null);
  const [tableHistoryData, setTableHistoryData] = useState([]);

  // start test
  function isRiskMissing(clientDetail) {
    return clientDetail?.risk === null;
  }

  function isPersonalDetailsMissing(clientDetail) {
    console.log("isPersonalDetailsMissing clientDetail:", clientDetail);
    if (clientDetail?.detail === null) return true;
    return (
      clientDetail?.detail?.firstName === "" ||
      clientDetail?.detail?.firstName === null ||
      clientDetail?.detail?.lastName === "" ||
      clientDetail?.detail?.lastName === null
    );
  }

  function isAddressMissing(clientDetail) {
    console.log("isAddressMissing clientDetail:", clientDetail);
    if (clientDetail?.residentialAddress === null) return true;
    return (
      clientDetail?.residentialAddress?.countryCode === "" ||
      clientDetail?.residentialAddress?.countryCode === null
    );
  }

  function isOccupationDetailsMissing(clientDetail) {
    console.log("isOccupationDetailsMissing clientDetail:", clientDetail);
    if (clientDetail?.occupation === null) return true;
    return (
      clientDetail?.occupation?.incomeCode === "" ||
      clientDetail?.occupation?.incomeCode === null ||
      clientDetail?.occupation?.sourceOfIncomeCode === "" ||
      clientDetail?.occupation?.sourceOfIncomeCode === null
    );
  }

  useEffect(() => {
    requestUntHldDetailAccountLockedStatus({ UserName: UserName });
    requestUnthldDetailProfile();
    REQUsrActSrch({
      page: "1",
      tSrch: customerUuid,
      tOpSrch: "UUID",
      tSortH: "created_at DESC",
      tFltActStage: ["Verified by", "Soft Onboarding", "Approve KYC"],
    });
  }, []);

  useEffect(() => {
    // Optimistically set to false
    setDisableApprove(false);

    if (typeof clientDetail !== "object" || clientDetail === null) {
      setDisableApprove(true);
      // Early exit if clientDetail isn't valid
      return;
    }

    if (isRiskMissing(clientDetail)) setDisableApprove(true);
    if (isPersonalDetailsMissing(clientDetail)) setDisableApprove(true);
    if (isAddressMissing(clientDetail)) setDisableApprove(true);
    if (isOccupationDetailsMissing(clientDetail)) setDisableApprove(true);
  }, [clientDetail]);
  // end test

  // * save page stage : ดูว่ามีการ reload หรือไม่
  useEffect(() => {
    console.log("unitholderMethod:", unitholderMethod);
    const savePageState = () => {
      const dataFromList = JSON.parse(
        localStorage.getItem("currentAutoApproveDetail")
      );

      setUserAccountDetail(dataFromList);
      localStorage.setItem(
        "currentAutoApproveDetail",
        JSON.stringify(dataFromList)
      );
    };

    // Save initial state
    savePageState();

    const handleBeforeUnload = () => {
      savePageState();
    };

    const handlePageShow = (event) => {
      if (!event.persisted) {
        const storedData = JSON.parse(
          localStorage.getItem("currentAutoApproveDetail")
        );
        if (storedData) {
          console.log("stored", storedData);
        }
      }
    };

    // Check for page reload using Performance API
    const navigationType =
      window.performance.getEntriesByType("navigation")[0]?.type;
    if (navigationType === "reload") {
      setIsPageReload(true);
      const storedData = JSON.parse(
        localStorage.getItem("currentAutoApproveDetail")
      );
      if (storedData) {
        history.push(
          `/AutoApproveDetail/${storedData.uuid}/UnitHolderID=${storedData.unitholderid}`
        );
      }
    }

    // Handle navigation events
    const unlisten = history.listen((location, action) => {
      if (action === "POP" && !isPageReload && !isUnloading) {
        history.replace({
          pathname: "/AutoApprove",
          PageCurrent: PageCurrent,
          UISearch: UISearch,
        });
      }
    });

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("pageshow", handlePageShow);
      unlisten();

      // Only clean up stored data if we're not reloading and not unloading
      if (!isPageReload && !isUnloading) {
        localStorage.removeItem("currentAutoApproveDetail");
      }
    };
  }, []);

  // #region : CALL API - Table History Log
  useEffect(() => {
    console.log("FETCH_TABLE_HISTORY_LOG_PAGINATION");
    FETCH_TABLE_HISTORY_LOG_PAGINATION({
      customerUuid,
      start: 0,
      limit: 10,
    });
    setTableHistoryData(TABLE_HISTORY_LOG_DATA_PAGINATION);
    console.dir(TABLE_HISTORY_LOG_DATA_PAGINATION);
  }, [FETCH_TABLE_HISTORY_LOG_PAGINATION, currentTab]);

  // #endregion

  const handleApproveClientClick = () => {
    SETxShwApvCliAlr(true);
    setActionType("APPROVE");
  };

  const handleRejectClientClick = () => {
    SETxShwApvCliAlr(true);
    setActionType("REJECT");
  };

  const handleCancelClick = () => {
    SETxShwApvCliAlr(true);
    setActionType("CANCEL");
  };

  // start test
  const handleApprove = () => {
    if (oShwApvCliAlr && actionType === "APPROVE") {
      // Check if any documents have been rejected
      setCountModalErrorApprove(true);

      let aFltDocReject =
        documentDetails.length === 0
          ? null
          : documentDetails.filter((f) => f.statusCode === "3");

      if (aFltDocReject.length > 0) {
        let aDocName = "";

        for (let a = 0; a < aFltDocReject.length; a++) {
          aDocName += aFltDocReject[a].typeCode
            ? documentTypeNameMapping()[aFltDocReject[a].typeCode]
            : "Default.jpeg";
          aDocName += ",";
        }

        aDocName = aDocName.substring(0, aDocName.length - 1);
        SETxAlertType({
          AlertType: "DocHaveReject",
          ShowAlert: true,
          Message: `The document file ${aDocName} has been rejected. Please approve all documents before approving the client.`,
        });
        return;
      }
    }
    requestUntHldDetailApprove();
  };

  const handleReject = () => {
    requestUntHldDetailReject();
  };

  const handleCancel = () => {
    requestUntHldDetailCancelStatus();
  };

  const GOxAproveCli = () => {
    SETxShwApvCliAlr(false);

    // Guard Clause
    if (!oShwApvCliAlr || !actionType) return;

    if (actionType === "APPROVE") {
      handleApprove();
    } else if (actionType === "REJECT") {
      handleReject();
    } else if (actionType === "CANCEL") {
      handleCancel();
    }
  };
  // end test

  const renderDocumentFloater = () => {
    return <ClientDocuments avatar={avatar} />;
  };
  // start test
  const hasDocumentsWithStatus = (status) => {
    const filteredDocs =
      documentDetails?.filter((f) => f.statusCode === status) || [];
    return filteredDocs.length >= 1;
  };

  const SHWxBtnApprove = () => {
    const hasPendingDocs = hasDocumentsWithStatus("1");
    let isAccountPendingOrCreating =
      clientDetail?.statusCode === accStatus.PENDING ||
      clientDetail?.statusCode === accStatus.CREATING;
    //eakapong test
    //isAccountPendingOrCreating = true;
    //
    return isAccountPendingOrCreating ? (
      <Fragment>
        {/* <button
          className={
            hasPendingDocs
              ? "client-detail-profile-approve-client-disabled"
              : "client-detail-profile-approve-client"
          }
          disabled={hasPendingDocs || disableApprove || unitholderMethod === "MANUAL_APPROVE" || unitholderMethod === "MANUAL"}
          onClick={() => handleApproveClientClick()}
        >
          APPROVE CLIENT
        </button>
        <button
          className={
            hasPendingDocs
              ? "client-detail-profile-reject-client-disabled"
              : "client-detail-profile-reject-client"
          }
          disabled={hasPendingDocs || disableApprove || unitholderMethod === "MANUAL_APPROVE" || unitholderMethod === "MANUAL"}
          onClick={() => handleRejectClientClick()}
        >
          REJECT CLIENT
        </button> */}
      </Fragment>
    ) : null;
  };
  // end test

  const SHWxUitHolderId = () => {
    return unitholderId;
    // if(unitholderId!=="") {
    //   return unitholderId;
    // }
    // else {
    //   console.log("unitholderIdFrmApprove:",unitholderIdFrmApprove);
    //   window.history.pushState({}, null, `/UnitHolderDetail/${customerUuid}/UnitHolderID=${unitholderIdFrmApprove}`);
    //   return unitholderIdFrmApprove;
    // }
  };

  const renderProfile = () => {
    if (
      requestingClientProfile ||
      requestingUnlock ||
      REQStrUsrActSrch ||
      savingtodb ||
      uploadingToS3
    ) {
      return (
        <div className="client-detail-profile-loader">
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div className="client-detail-profile-container">
          {renderDocumentFloater()}
          <div className="client-detail-profile-header">
            <div className="client-detail-profile-account-status">
              <p className="client-detail-profile-account-status-text">
                ACCOUNT STATUS
              </p>
              {clientDetail?.statusCode === accStatus.PENDING ? (
                <Fragment>
                  <img src={Images.statusIcon.pending} alt="pending-icon" />
                  <p className="client-detail-profile-account-status-pending">
                    Pending
                  </p>
                </Fragment>
              ) : null}
              {clientDetail?.statusCode === accStatus.APPROVED ? (
                <Fragment>
                  <img src={Images.statusIcon.approved} alt="approved-icon" />
                  <p className="client-detail-profile-account-status-approved">
                    Approved
                  </p>
                </Fragment>
              ) : null}
              {clientDetail?.statusCode === accStatus.REJECTED ? (
                <Fragment>
                  <img src={Images.statusIcon.rejected} alt="rejected-icon" />
                  <p className="client-detail-profile-account-status-rejected">
                    Rejected
                  </p>
                </Fragment>
              ) : null}
              {clientDetail?.statusCode === accStatus.CREATING ? (
                <Fragment>
                  <img src={Images.statusIcon.pending} alt="rejected-icon" />
                  <p className="client-detail-profile-account-status-pending">
                    Creating
                  </p>
                </Fragment>
              ) : null}
            </div>

            <div className="client-detail-profile-actions-container">
              {clientDetail?.statusCode === accStatus.APPROVED ||
              clientDetail?.statusCode === accStatus.REJECTED ? (
                <Fragment>
                  {/* <button
                  className="client-detail-profile-approve-client"
                  onClick={() => handleCancelClick()}
                >
                  CANCEL
                </button> */}
                </Fragment>
              ) : null}
              {SHWxBtnApprove()}
            </div>
          </div>
          {}
          {}
          <div className="client-detail-profile-account-photo-container">
            <p>PHOTO</p>
            <div>
              <img src={document9Selfie} />
              <p>SELFIE WITH ID</p>
            </div>
            <div>
              <img src={profilePic} />
              <p>PROFILE PHOTO</p>
            </div>
          </div>
          <div className="client-detail-profile-account-unitholderid-container">
            <p className="client-detail-profile-account-unitholderid-text">
              UNIT HOLDER ID
            </p>
            <p className="client-detail-profile-account-unitholderid-value">
              {SHWxUitHolderId()}
            </p>
          </div>
          <PersonalDetailsForm />
        </div>
      );
    }
  };

  const renderFunds = () => {
    return <ClientFunds />;
  };

  // * [RENDER] : HISTORY LOG

  useEffect(() => {
    setTableHistoryData(TABLE_HISTORY_LOG_DATA_PAGINATION);
  }, [TABLE_HISTORY_LOG_DATA_PAGINATION]);

  const handlePageChange = (newPage) => {
    const newStart = newPage * 10;
    FETCH_TABLE_HISTORY_LOG_PAGINATION({
      start: newStart,
      limit: 10,
    });

    setTableHistoryData(TABLE_HISTORY_LOG_DATA_PAGINATION);
  };

  const renderHistoryLog = () => {
    return (
      <TableHistoryLogPagination
        columns={HeadCellsHistoryLog}
        data={tableHistoryData}
        onPageChange={handlePageChange}
      />
    );
  };

  const SHWxLockedStatus = (oLock) => {
    if (oLock === "UNLOCKED") {
      return (
        <Fragment>
          <img
            src={Images.accessStatus.unlocked}
            alt="locked-icon"
            className="client-detail-header-unlocked-img"
          />
          <p className="client-detail-header-unlocked">Unlocked</p>
        </Fragment>
      );
    } else if (oLock === "LOCKED") {
      return (
        <Fragment>
          <img
            src={Images.accessStatus.locked}
            alt="locked-icon"
            className="client-detail-header-locked-img"
          />
          <p className="client-detail-header-locked">Locked</p>
        </Fragment>
      );
    } else if (oLock === "User not found") {
      return (
        <Fragment>
          <img
            src={Images.accessStatus.locked}
            alt="locked-icon"
            className="client-detail-header-locked-img"
          />
          <p className="client-detail-header-locked">
            Okta user E-mail not found
          </p>
        </Fragment>
      );
    }
  };

  const SuspendButton = (status) => {
    return (
      <Fragment>
        {/* <BasicButton
          bgColor="#0275b6"
          textColor="#ffffff"
          onClick={() => alert("Full Button Clicked!")}
          disabled={status}
        >
          SUSPEND
        </BasicButton> */}
      </Fragment>
    );
  };
  const SETxAlrErrWhenAprove = () => {
    if (CountModalErrorApprove === true) {
      if (errorMessage) {
        SETxAlertType({
          AlertType: "ErrApprove",
          ShowAlert: true,
          Message: errorMessage.header,
        });
      }
      setCountModalErrorApprove(false);
    }
  };
  const SHWxAlrAprove = () => {
    // console.log("SHWxAlrAprove oAlertType:",oAlertType);
    return (
      <AlertModal
        show={oAlertType.ShowAlert}
        onHide={() =>
          SETxAlertType({
            AlertType: "",
            ShowAlert: false,
            Message: "",
          })
        }
        onHandleOK={() => {
          SETxAlertType({
            AlertType: "",
            ShowAlert: false,
            Message: "",
          });
        }}
        type={"Disapprove"}
        status={"1"}
        title={oAlertType.Message + ""}
      />
    );
  };

  return (
    <Fragment>
      <ErrorModal
        show={errorMessage && errorMessage.length > 0}
        onHide={() => clearUntHldDetailErrorMessage()}
        errorMessageText={errorMessage ? errorMessage : ""}
      />
      <WarningModal
        show={oShwApvCliAlr}
        onHide={() => {
          SETxShwApvCliAlr(false);
          setActionType("");
        }}
        onHandleGo={() => {
          GOxAproveCli();
        }}
        onHandleCancel={() => {
          SETxShwApvCliAlr(false);
          setActionType("");
        }}
        type={actionType}
        status={""}
        title={`Are you sure you want to ${actionType.toLowerCase()} the Client KYC?`}
      />
      {/*-------SET alert error  aprove  when error-----------------------*/}
      {requestingClientApproval ? null : SETxAlrErrWhenAprove()}
      {/*---------Show alert type -----*/}
      {SHWxAlrAprove()}

      {requestingClientApproval ||
      requestingClientRejection ||
      requestingCancelStatus ? (
        <div className="client-details-whole-page-progress">
          <div className="client-details-progress">
            <CircularProgress />
            <p className="client-details-progress-text">
              {requestingClientApproval
                ? "Requesting client approval..."
                : null}
              {requestingClientRejection
                ? "Requesting client rejection..."
                : null}
              {requestingCancelStatus ? "Requesting cancellation..." : null}
            </p>
          </div>
        </div>
      ) : null}
      <div className="client-detail-header">
        <p>ACCESS STATUS</p>
        {requestingLockedStatus ? (
          <CircularProgress size={16} className="profile-tab-progress" />
        ) : (
          SHWxLockedStatus(isLockedStatus)
        )}

        <div className="client-detail-header-line" />
        {/* ซ่อนไว้ก่อน */}
        {requestingLockedStatus ||
        requestingUnlock ||
        isLockedStatus === "UNLOCKED" ||
        isLockedStatus === false ? (
          <Fragment>
            {/* <button
            className="client-detail-header-button-disabled"
            disabled={true}
          >
            UNLOCK
          </button> */}
          </Fragment>
        ) : (
          <Fragment>
            {/* <button
            className="client-detail-header-button"
            onClick={() => requestUntHldDetailAccountUnlock()}
          >
            UNLOCK
          </button> */}
          </Fragment>
        )}
        <div
          style={{
            marginLeft: "1rem",
          }}
        >
          {/* ระงับการใช้งาน */}
          {SuspendButton()}
        </div>
      </div>
      <div className="client-detail-container">
        <div className="client-detail-tabs-container">
          <button
            className={
              currentTab === "PROFILE"
                ? "client-detail-tab-profile"
                : "client-detail-tab-profile-unselected"
            }
            onClick={() => setCurrentTab("PROFILE")}
          >
            PROFILE
            {requestingClientProfile ? (
              <CircularProgress size={16} className="profile-tab-progress" />
            ) : null}
            {clientDetail?.statusCode === accStatus.PENDING &&
            !requestingClientProfile ? (
              <img src={Images.statusIcon.pending} alt="pending-icon" />
            ) : null}
            {clientDetail?.statusCode === accStatus.APPROVED &&
            !requestingClientProfile ? (
              <img src={Images.statusIcon.approved} alt="approved-icon" />
            ) : null}
            {clientDetail?.statusCode === accStatus.REJECTED &&
            !requestingClientProfile ? (
              <img src={Images.statusIcon.rejected} alt="rejected-icon" />
            ) : null}
          </button>
          <button
            className={
              currentTab === "FUNDS"
                ? "client-detail-tab-funds"
                : "client-detail-tab-funds-unselected"
            }
            onClick={() => setCurrentTab("FUNDS")}
          >
            FUNDS
          </button>
          {/* TAB : History Log */}
          <button
            className={
              currentTab === "HISTORY_LOG"
                ? "client-detail-tab-history-log"
                : "client-detail-tab-history-log-unselected"
            }
            onClick={() => setCurrentTab("HISTORY_LOG")}
          >
            HISTORY LOG
          </button>
        </div>
        {currentTab === "PROFILE" ? renderProfile() : null}
        {currentTab === "FUNDS" ? renderFunds() : null}
        {currentTab === "HISTORY_LOG" ? renderHistoryLog() : null}
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  (state) => ({
    avatar:
      state.autoApproveDetailReducer.clientDetail?.detail &&
      state.autoApproveDetailReducer.clientDetail?.detail.avatar &&
      state.autoApproveDetailReducer.clientDetail?.detail.avatar.url
        ? state.autoApproveDetailReducer.clientDetail?.detail.avatar.url
        : Images.avatar.avatar,
    profilePic:
      state.autoApproveDetailReducer.clientDetail?.detail &&
      state.autoApproveDetailReducer.clientDetail?.detail.profilePic &&
      state.autoApproveDetailReducer.clientDetail?.detail.profilePic.url
        ? state.autoApproveDetailReducer.clientDetail?.detail.profilePic.url
        : Images.avatar.avatar,

    // form document.js set on reducer
    document9Selfie: state.autoApproveDetailReducer.document9Selfie,
    documentDetails: state.autoApproveDetailReducer.documentDetails,
    TABLE_HISTORY_LOG_DATA_PAGINATION:
      state.autoApproveDetailReducer.TABLE_HISTORY_LOG_DATA_PAGINATION,

    ...state.autoApproveDetailReducer,
  }),
  {
    requestUnthldDetailProfile,
    requestUntHldDetailDocuments,
    requestUntHldDetailAccountLockedStatus,
    requestUntHldDetailAccountUnlock,
    requestUntHldDetailApprove,
    requestUntHldDetailReject,
    requestUntHldDetailCancelStatus,
    clearUntHldDetailErrorMessage,
    // -----UserAccount----

    REQUsrActSrch,

    // ----- TABLE HISTORY LOG ------
    FETCH_TABLE_HISTORY_LOG_PAGINATION,
  }
);

export default compose(withConnect, withRouter)(AutoApproveDetail);
