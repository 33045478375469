import { createReducer } from "redux-act";

import {
  // --------------UntHld ACCOUNT-----------------
  REQOcrLog,
  RESOcrLog,
  FailOcrLog,
  CleanUpOcrLog,
  REQManualEmail,
  RESManualEmail,
  FailManualEmail
  // ---------DisArpove----
} from "./actions";

const initialState = {
  REQStrOcrLog: false,
  OcrLog: [],
  errorMessage: false,
  totalUntHldAct: 0,

  REQStrManual: false,
  sendEmailResult: [],
};

const ocrLogReducer = createReducer(
  {
  
    [REQOcrLog]: (state) => {
      return {
        ...state,
        REQStrOcrLog: true
      };
    },
   
    [RESOcrLog]: (state, payload) => {
     
      return {
        ...state,
        REQStrOcrLog: false,
        OcrLog: payload.data,
        totalUntHldAct: payload.total
      };
    },
     
    [FailOcrLog]: (state) => {
      return {
        ...state,
        REQStrOcrLog: false,
        OcrLog: [],
        totalUntHldAct: 0
      };
    } , 
    [CleanUpOcrLog]: () => {
      return initialState;
    },
    // manual email
    [REQManualEmail]: (state) => {
      return {
        ...state,
        REQStrManual: true
      };
    },
   
    [RESManualEmail]: (state, payload) => {
     
      return {
        ...state,
        REQStrManual: false,
        sendEmailResult: payload.data,
      };
    },
     
    [FailManualEmail]: (state) => {
      return {
        ...state,
        REQStrManual: false,
        sendEmailResult: [],
      };
    } , 
    
  },

  initialState
);

export default ocrLogReducer;
