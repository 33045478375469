import React, { useState, useEffect } from "react";

export default function BasicDropdown({
  options,
  label,
  onChange,
  headers,
  value,
  debug = false,
  disabled = false,
}) {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onChange) onChange(value); // Notify parent component if needed
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  
  return (
    <div style={{ margin: "1rem 0" }}>
      {label && (
        <label style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
          {label}
        </label>
      )}
      <select
        value={selectedValue}
        onChange={handleChange}
        disabled={disabled}
        style={{
          width: "100%",
          height: "4.5rem",
          padding: "1rem 0rem 1rem 1rem",
          fontSize: "1.5rem",
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginTop: "0.5rem",
          appearance: "none", // Hide default arrow
          background: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>') no-repeat right 1rem center`,
          backgroundColor: "#fff",
          backgroundSize: "1.2rem",
        }}
      >
        {options.map((option, index) => (
          <option
            key={index}
            value={option.value}
            selected={option.selected}
            disabled={option.disabled}
          >
            {option.label_th} ({option.label_en})
          </option>
        ))}
      </select>
      {selectedValue && debug === true && (
        <p style={{ marginTop: "0.5rem" }}>Selected: {selectedValue}</p>
      )}
    </div>
  );
}
