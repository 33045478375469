import TransactionDetails from "../../containers/TransactionDetails";
import RequestATSDetails from "../../containers/RequestATSDetails";

import CusServiceList from "../../containers/CusServiceList/CusServiceList";
import CusServiceDetail from "../../containers/CusServiceDetail/CusServiceDetail";

import UsrActList from "../../containers/UsrActList/UsrActList";
import UsrActDetail from "../../containers/UsrActDetail/UsrActDetail";

import ImpRGTtoB2CList from "../../containers/ImpRGTtoB2CList/ImpRGTtoB2CList";

import ConFdDetail from "../../containers/ContentFeedDetail/ConFdDetail";

import ConFdCrt from "../../containers/ContentFeedDetail/ConFdCrt";
import ConFdNotiInvestCrt from "../../containers/ContentFeedDetail/ConFdNotiInvestCrt";

import ConFdNotiInvestDetail from "../../containers/ContentFeedDetail/ConFdNotiInvestDetail";

import ContentFeedList from "../../containers/ContentFeedList/ContentFeedList";
import FundDetail from "../../containers/FundDetail/FundDetail";
import FundList from "../../containers/FundList/FundList";
import Applicants from "../../containers/Applicants";
import RSPAccountDetails from "../../containers/RSPAccountDetails";

import FundRecList from "../../containers/FundRecommendList/FundRecList";
import FundRecCodiList from "../../containers/FundRecommendList/FundRecCodiList";
import FundRecRiskList from "../../containers/FundRecommendList/FundRecRiskList";
import FundRecCodiDetail from "../../containers/FundRecommendDetail/FundRecCodiDetail";
import FundRecCodiCrt from "../../containers/FundRecommendDetail/FundRecCodiCrt";
import FundRecRiskDetail from "../../containers/FundRecommendDetail/FundRecRiskDetail";

import TagList from "../../containers/TagList/TagList";

import SrchRecList from "../../containers/SrchRecList/SrchRecList";
import SrchRecDetail from "../../containers/SrchRecDetail/SrchRecDetail";

import ContentFeedSortList from "../../containers/ContentFeedSortList/ContentFeedSortList";
import TagCrt from "../../containers/TagDetail/TagCrt";
import TagDetail from "../../containers/TagDetail/TagDetail";

import RegisDeviceList from "../../containers/RegisDeviceList/RegisDeviceList";
import RegisDeviceDetail from "../../containers/RegisDeviceDetail/RegisDeviceDetail";

import ActiList from "../../containers/ActiList/ActiList";
import ActiDetail from "../../containers/ActiDetail/ActiDetail";

import UntHldList from "../../containers/UntHldList/UntHldList";
import UntHldDetail from "../../containers/UntHldDetail/UntHldDetail";
import FullImage from "../../containers/UntHldDetail/FullImage";

import RegisLogList from "../../containers/RegisLogList/RegisLogList";
import RegisLogDetail from "../../containers/RegisLogDetail/RegisLogDetail";

import NDIDLogList from "../../containers/RegisLogList/NDIDLogList";
import NDIDLogDetail from "../../containers/RegisLogDetail/NDIDLogDetail";

import ConsentList from "../../containers/ConsentList/ConsentList";
import ConsentVSList from "../../containers/ConsentVSList/ConsentVSList";
import ConsentVSDetail from "../../containers/ConsentVSDetail/ConsentVSDetail";

import AnalyticList from "../../containers/AnalyticList/AnalyticList";
import AnalyticDetail from "../../containers/AnalyticDetail/AnalyticDetail";

import AnalyticUntHldList from "../../containers/AnalyticList/AnalyticUntHldList";
import AnalyticUntHldDetail from "../../containers/AnalyticDetail/AnalyticUntHldDetail";
import AutoApprove from "../../containers/AutoApprove/AutoApprove";
import AutoApproveDetail from "../../containers/AutoApproveDetail/AutoApproveDetail";
import OcrLog from "../../containers/OcrLog/OcrLog";

const routes = [
  {
    id: 19,
    path: "/",
    name: "UsrAct",
    component: UsrActList,
    exact: true
  },
  {
    id: 191,
    path: "/NDID",
    name: "NDID",
    component: CusServiceList,
    exact: true
  },
  {
    id: 192,
    path: "/CouterService",
    name: "Couter_Service",
    component: CusServiceList,
    exact: true
  },
  {
    id: 193,
    path: "/UsrAct",
    name: "UsrAct",
    component: UsrActList,
    exact: true
  },
  {
    id: 194,
    path: "/UnitHolder",
    name: "Unitholder",
    component: UntHldList,
    exact: true
  },
  {
    id: 198,
    path: "/AutoApprove",
    name: "AutoApprove",
    component: AutoApprove,
    exact: true
  },
  {
    id: 1981,
    path: "/AutoApproveDetail/:uuid/:unitholderId",
    name: "AutoApproveDetail",
    component: AutoApproveDetail,
    exact: true
  },
  {
    id: 195,
    path: "/UnitHolderDetail/:uuid/:unitholderId",
    name: "UnitHolderDetail",
    component: UntHldDetail,
    exact: true
  },
  {
    id: 196,
    path: "/ImportRGTtoB2C",
    name: "IMPRGTtoB2C",
    component: ImpRGTtoB2CList,
    exact: true
  },
  {
    id: 197,
    path: "/FullDocImage",
    name: "FullDocImage",
    component: FullImage,
    exact: true
  },
  {
    id: 199,
    path: "/OcrLog",
    name: "OcrLog",
    component: OcrLog,
    exact: true
  },
  // ------tab1---------
  {
    id: 1914,
    path: "/UnitHolderTab1Detail/:uuid/:unitholderId",
    name: "UnitHolderTab1Detail",
    component: UntHldDetail,
    exact: true
  },
  {
    id: 1911,
    path: "/NDIDDetail/:id",
    name: "NDIDDetail",
    component: CusServiceDetail,
    exact: true
  },
  {
    id: 1912,
    path: "/CouterServiceDetail/:id",
    name: "CouterServiceDetail",
    component: CusServiceDetail,
    exact: true
  },
  {
    id: 1913,
    path: "/UsrActDetail/:id",
    name: "UserAccountDetail",
    component: UsrActDetail,
    exact: true
  },
  // ------tab2---------
  {
    id: 1914,
    path: "/RegisTab2DeviceDetail/:id",
    name: "RegisTab2DeviceDetail",
    component: RegisDeviceDetail,
    exact: true
  },
  // ------Button ViewLog---------
  {
    id: 1915,
    path: "/UsrActDetail_ViewLog",
    name: "UsrActDetail ViewLog",
    component: ActiList,
    exact: true
  },
  // ------Button ViewLog Detail---------
  {
    id: 1916,
    path: "/UsrActDetail_ViewLogDetail/:id",
    name: "UsrActDetail ViewLogDetail",
    component: ActiDetail,
    exact: true
  },
  {
    id: 3,
    path: "/transaction",
    name: "Transaction",
    redirect: "/transaction_details",
    component: TransactionDetails,
    exact: true
  },
  {
    id: 2,
    path: "/fund_list",
    name: "FundList",
    component: FundList,
    exact: true
  },
  {
    id: 5,
    path: "/fund_listDetail/:id",
    name: "FundDetails",
    component: FundDetail,
    exact: true
  },
  {
    id: 6,
    path: "/transaction_details",
    name: "TransactionDetails",
    component: TransactionDetails,
    exact: true
  },
  {
    id: 7,
    path: "/request_ats_details",
    name: "RequestATSDetails",
    component: RequestATSDetails,
    exact: true
  },
  {
    id: 8,
    path: "/ConFd",
    name: "ContentFeedList",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 82,
    path: "/ConFd_Invest",
    name: "ConFd_Invest",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 83,
    path: "/ConFd_News",
    name: "ConFd_News",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 84,
    path: "/ConFd_ContentInvest",
    name: "ConFd_ConInvest",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 85,
    path: "/ConFd_ManualInvest",
    name: "ConFd_ManualInvest",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 86,
    path: "/ConFd_PVDNewsReport",
    name: "ConFd_PVDNewsReport",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 87,
    path: "/ConFd_PVDPlaning",
    name: "ConFd_PVDPlaning",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 88,
    path: "/ConFd_PVDFactSheet",
    name: "ConFd_PVDFactSheet",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 89,
    path: "/ConFd_NotifyInvest",
    name: "ConFd_NotiInvest",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 90,
    path: "/ConFd_Srch",
    name: "ConFd_Srch",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 91,
    path: "/ConFd_Others",
    name: "ConFd_Others",
    component: ContentFeedList,
    exact: true
  },
  {
    id: 851,
    path: "/ConFd_NewsOldDetail/:id",
    name: "ConFd_NewsOld_Detail",
    component: ConFdDetail,
    exact: true
  },
  {
    id: 852,
    path: "/ConFd_InvestDetail/:id",
    name: "ConFd_Invest_Detail",
    component: ConFdDetail,
    exact: true
  },
  {
    id: 853,
    path: "/ConFd_NewsDetail/:id",
    name: "ConFd_News_Detail",
    component: ConFdDetail,
    exact: true
  },
  {
    id: 854,
    path: "/ConFd_ContentInvestDetail/:id",
    name: "ConFd_ContentInvest_Detail",
    component: ConFdDetail,
    exact: true
  },
  {
    id: 855,
    path: "/ConFd_ManualInvestDetail/:id",
    name: "ConFd_ManualInvest_Detail",
    component: ConFdDetail,
    exact: true
  },

  {
    id: 856,
    path: "/ConFd_PVDNewsReportDetail/:id",
    name: "ConFd_PVDNewsReport_Detail",
    component: ConFdDetail,
    exact: true
  },

  {
    id: 857,
    path: "/ConFd_PVDPlaningDetail/:id",
    name: "ConFd_PVDPlaning_Detail",
    component: ConFdDetail,
    exact: true
  },

  {
    id: 858,
    path: "/ConFd_PVDFactSheetDetail/:id",
    name: "ConFd_PVDFactSheet_Detail",
    component: ConFdDetail,
    exact: true
  },

  {
    id: 859,
    path: "/ConFd_NotifyInvestDetail/:id",
    name: "ConFd_NotifyInvest_Detail",
    component: ConFdNotiInvestDetail,
    exact: true
  },
  {
    id: 860,
    path: "/ConFd_SrchFdDetail/:id",
    name: "ConFd_SrchFd_Detail",
    component: ConFdDetail,
    exact: true
  },
  {
    id: 861,
    path: "/ConFd_SrchNotiDetail/:id",
    name: "ConFd_SrchNoti_Detail",
    component: ConFdNotiInvestDetail,
    exact: true
  },
  {
    id: 8501,
    path: "/ConFdCrt",
    name: "ConFdCrt",
    component: ConFdCrt,
    exact: true
  },
  {
    id: 8502,
    path: "/ConFdNotiInvestCrt",
    name: "ConFdNotiInvestCrt",
    component: ConFdNotiInvestCrt,
    exact: true
  },
  {
    id: 9,
    path: "/ConFd/:id",
    name: "ContentFeed",
    component: ConFdDetail,
    exact: true
  },
  {
    id: 10,
    path: "/applicants",
    name: "Applicants",
    component: Applicants,
    exact: true
  },
  {
    id: 11,
    path: "/rsp_account_details",
    name: "RSPAccountDetails",
    component: RSPAccountDetails,
    exact: true
  },
  {
    id: 12,
    path: "/FundRec_FundMan",
    name: "FundRec",
    component: FundRecList,

    exact: true
  },
  {
    id: 121,
    path: "/FundRec_FundMan",
    name: "FundRec",
    exact: true
  },
  {
    id: 1211,
    path: "/FundRec_FundManView/:id",
    name: "FundRec_FundManView",
    component: FundDetail,
    exact: true
  },
  {
    id: 1212,
    path: "/FundRec_FundManEdit/:id",
    name: "FundRec_FundManEdit",
    component: FundDetail,
    exact: true
  },
  {
    id: 1212,
    path: "/FundRec_FundManCrt/:id",
    name: "FundRec_FundManCrt",
    component: FundDetail,
    exact: true
  },
  {
    id: 122,
    path: "/FundRec_Condi",
    name: "FundRec_Condi",
    component: FundRecCodiList,
    exact: true
  },
  {
    id: 123,
    path: "/FundRec_FundRisk",
    name: "FundRec_FundRisk",
    component: FundRecRiskList,
    exact: true
  },
  {
    id: 1221,
    path: "/FundRec_CodiDetail/:id",
    name: "FundRec_Codi_Detail",
    component: FundRecCodiDetail,
    exact: true
  },
  {
    id: 1222,
    path: "/FundRec_CodiCrt",
    name: "FundRec_Codi_Crt",
    component: FundRecCodiCrt,
    exact: true
  },
  {
    id: 1231,
    path: "/FundRec_RiskDetail/:id",
    name: "FundRec_Risk_Detail",
    component: FundRecRiskDetail,
    exact: true
  },
  {
    id: 13,
    path: "/Tag",
    name: "Tag",
    component: TagList,
    exact: true
  },
  {
    id: 131,
    path: "/TagDetail/:id",
    name: "Tag_Detail",
    component: TagDetail,
    exact: true
  },
  {
    id: 132,
    path: "/TagCrt",
    name: "TagCrt",
    component: TagCrt,
    exact: true
  },
  {
    id: 15,
    path: "/SearchRec",
    name: "SearchRec",
    component: SrchRecList,
    exact: true
  },
  {
    id: 151,
    path: "/SrchRecDetail",
    name: "SearcRecDetail",
    component: SrchRecDetail,
    exact: true
  },
  {
    id: 16,
    path: "/ContenetFeedSort",
    name: "Contenet Feed Sort",
    component: ContentFeedSortList,
    exact: true
  },
  {
    id: 17,
    path: "/RegisDevice",
    name: "RegisterDevice",
    component: RegisDeviceList,
    exact: true
  },
  {
    id: 171,
    path: "/RegisDeviceDetail/:id",
    name: "RegisDeviceDetail",
    component: RegisDeviceDetail,
    exact: true
  },
  {
    id: 18,
    path: "/Activity",
    name: "Activity",
    component: ActiList,
    exact: true
  },
  {
    id: 1811,
    path: "/Activity_Usr",
    name: "Activity_Usr",
    component: ActiList,
    exact: true
  },
  {
    id: 1812,
    path: "/Activity_Admin",
    name: "Activity_Admin",
    component: ActiList,
    exact: true
  },
  {
    id: 181,
    path: "/ActivityDetail_Usr/:id",
    name: "ActivityDetail_Usr",
    component: ActiDetail,
    exact: true
  },
  {
    id: 182,
    path: "/ActivityDetail_Admin/:id",
    name: "ActivityDetail_Admin",
    component: ActiDetail,
    exact: true
  },
  {
    id: 20,
    path: "/RegisLog",
    name: "RegisLog",
    component: RegisLogList,
    exact: true
  },
  {
    id: 201,
    path: "/RegisLog_CounterService",
    name: "RegisLog_CounterService",
    component: RegisLogList,
    exact: true
  },
  {
    id: 202,
    path: "/RegisLog_NDID",
    name: "RegisLog_NDID",
    component: RegisLogList,
    exact: true
  },
  {
    id: 203,
    path: "/RegisLogDetail",
    name: "RegisLogDetail",
    component: RegisLogDetail,
    exact: true
  },
  {
    id: 204,
    path: "/NDIDLog",
    name: "NDIDLog",
    component: NDIDLogList,
    exact: true
  },
  {
    id: 205,
    path: "/NDIDLogDetail",
    name: "NDIDLogDetail",
    component: NDIDLogDetail,
    exact: true
  },
  {
    id: 21,
    path: "/Consent",
    name: "Consent",
    component: ConsentList,
    exact: true
  },
  {
    id: 22,
    path: "/Version",
    name: "Version",
    component: ConsentVSList,
    exact: true
  },
  {
    id: 221,
    path: "/VersionDetail",
    name: "VersionDetail",
    component: ConsentVSDetail,
    exact: true
  },
  {
    id: 23,
    path: "/Analytic",
    name: "Analytic",
    component: AnalyticList,
    exact: true
  },
  {
    id: 231,
    path: "/Analytic_EsyInv",
    name: "Analytic_EsyInv",
    component: AnalyticList,
    exact: true
  },
  {
    id: 232,
    path: "/Analytic_Apps",
    name: "Analytic_Apps",
    component: AnalyticUntHldList,
    exact: true
  },
  {
    id: 233,
    path: "/AnalyticDetail",
    name: "AnalyticDetail",
    component: AnalyticDetail,
    exact: true
  },
  {
    id: 234,
    path: "/AnalyticAppsDetail",
    name: "AnalyticAppsDetail",
    component: AnalyticUntHldDetail,
    exact: true
  }
];

export default routes;
