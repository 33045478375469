import React, { Fragment, useState } from "react";
import {
  TextInput,
  TextMultilineInput,
  SelectInput,
  RadioInput,
} from "../../../../../components/input_hook_form";
import "./style.scss";

// start test
function TabForms(props) {
  const { register, language, mode, control, errors } = props;
  const [currentTab, setCurrentTab] = useState("INVESTMENTPOLICY");
  const dividendOptions = [
    { value: "1", label: "Paid" },
    { value: "0", label: "Unpaid" },
  ];

  const handleTabClick = (tab) => {
    setCurrentTab(tab);
  };

  const renderTabButton = (tabName, buttonText) => {
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          handleTabClick(tabName);
        }}
        className={
          currentTab === tabName
            ? "tabforms-tabbutton-selected"
            : "tabforms-tabbutton"
        }
      >
        {buttonText}
      </button>
    );
  };

  const renderTextMultilineInput = (label, placeholder, name) => {
    return (
      <TextMultilineInput
        label={label}
        placeholder={placeholder}
        error={mode !== "VIEW" && errors[name + "-" + language]}
        register={register({ required: true, minLength: 1 })}
        type={"text"}
        name={name + "-" + language}
        disabled={mode === "VIEW"}
      />
    );
  };

  const renderSelectInput = (label, placeholder, name) => {
    return (
      <SelectInput
        label={label}
        placeholder={placeholder}
        error={mode !== "VIEW" && errors[name + "-" + language]}
        rules={{ required: true, minLength: 1 }}
        name={name + "-" + language}
        control={control}
        disabled={mode === "VIEW"}
      />
    );
  };

  const renderTextInput = (label, placeholder, name) => {
    return (
      <TextInput
        label={label}
        placeholder={placeholder}
        error={mode !== "VIEW" && errors[name + "-" + language]}
        register={register({ required: true, minLength: 1 })}
        type={"text"}
        name={name + "-" + language}
        disabled={mode === "VIEW"}
      />
    );
  };

  const renderTextInputBlockEdit = (label, placeholder, name) => {
    return (
      <TextInput
        label={label}
        placeholder={placeholder}
        error={mode !== "VIEW" && errors[name + "-" + language]}
        register={register({ required: true, minLength: 1 })}
        type={"text"}
        name={name + "-" + language}
        disabled={mode === "VIEW" || mode === "EDIT"}
      />
    );
  };

  const renderTextInputBlockEditNotValidated = (label, placeholder, name) => {
    return (
      <TextInput
        label={label}
        placeholder={placeholder}
        error={mode !== "VIEW" && errors[name + "-" + language]}
        register={register({ required: false })}
        type={"text"}
        name={name + "-" + language}
        disabled={mode === "VIEW"}
      />
    );
  };

  const renderRadioInput = (label, name) => {
    return (
      <RadioInput
        label={label}
        name={name}
        options={dividendOptions}
        control={control}
        rules={{ required: true }}
        disabled={mode === "VIEW"}
      />
    );
  };

  return (
    <Fragment>
      <div className="tabforms-tab-container">
        {renderTabButton("INVESTMENTPOLICY", "INVESTMENT POLICY")}
        {renderTabButton("FUNDNATURE", "FUND NATURE")}
        {renderTabButton("FEES", "FEES")}
      </div>
      <div className={"tabforms-spacer"} />

      <div
        className={
          currentTab === "INVESTMENTPOLICY"
            ? "tabforms-investmentpolicy"
            : "tabforms-hide"
        }
      >
        {renderTextMultilineInput(
          "Investment Policy",
          "Enter Investment Policy",
          "investmentpolicy"
        )}
      </div>

      <div
        className={
          currentTab === "FUNDNATURE" ? "tabforms-fundnature" : "tabforms-hide"
        }
      >
        {renderSelectInput("Fund Manager", "Enter Names", "fundmanager")}
        <div className={"tabforms-spacer"} />
        {renderSelectInput("Fund Type", "Enter Fund Types", "fundtype")}
        <div className={"tabforms-spacer"} />
        {renderTextInput("Fund Size", "Enter Fund Size", "fundsize")}
        {renderRadioInput("Dividend Policy", "dividendpolicy-option")}
        {renderTextInput(
          "Dividend Description",
          "Enter Dividend Policy",
          "dividendpolicy"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextMultilineInput("Benchmark", "Enter Benchmark", "benchmark")}
        <div className={"tabforms-spacer"} />
        {renderTextMultilineInput(
          "Hedging Policy",
          "Enter Hedging Policy",
          "hedgingpolicy"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextMultilineInput(
          "Subscription Period (IPO)",
          "Enter Subscription Period (IPO)",
          "periodipo"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextMultilineInput(
          "Subscription Period (After IPO)",
          "Enter Subscription Period (After IPO)",
          "periodipoafter"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextMultilineInput(
          "Redemption Period",
          "Enter Redemption Period",
          "redemptionperiod"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextInput(
          "Minimum Subscription",
          "Enter Minimum Subscription",
          "minsubscription"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextInput(
          "Next Subscription",
          "Enter Next Subscription",
          "nextsubscription"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextInputBlockEditNotValidated(
          "First_lowbuy_val",
          "Enter First_lowbuy_val",
          "firstlowbuy"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextInputBlockEditNotValidated(
          "Next_lowbuy_val",
          "Enter Next_lowbuy_val",
          "nextlowbuy"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextMultilineInput(
          "Settlement",
          "Enter Settlement",
          "settlement"
        )}
        <div className={"tabforms-spacer"} />
        {renderTextInput(
          "Dividend Payment History",
          "Enter Dividend Payment History",
          "dividendpaymenthistory"
        )}
      </div>

      <div
        className={currentTab === "FEES" ? "tabforms-fees" : "tabforms-hide"}
      >
        <h3>FEE & EXPENSES CHARGED TO THE UNITHOLDERS</h3>
        <div className={"unitholder-section"}>
          <p className={"mini-title"}>Front-end Fee and Switching In Fee</p>
          {renderTextInput("Prospectus", "Prospectus", "frontendprospectus")}
          <div className={"tabforms-spacer"} />
          {renderTextInput("Actual", "Actual", "frontendactual")}
          <div className={"tabforms-spacer"} />
          <p className={"mini-title"}>Back-end Fee and Switching Out Fee</p>
          {renderTextInput("Prospectus", "Prospectus", "backendprospectus")}
          <div className={"tabforms-spacer"} />
          {renderTextInput("Actual", "Actual", "backendactual")}
          <div className={"tabforms-spacer"} />
          <p className={"mini-title"}>Brokerage Fee</p>
          {renderTextInput("Prospectus", "Prospectus", "brokerageprospectus")}
          <div className={"tabforms-spacer"} />
          {renderTextInput("Actual", "Actual", "brokerageactual")}
        </div>
        <h3>FEE & EXPENSES CHARGED TO THE FUND</h3>
        <div className={"fund-section"}>
          <p className={"mini-title"}>Management Fee</p>
          {renderTextInput("Prospectus", "Prospectus", "managementprospectus")}
          <div className={"tabforms-spacer"} />
          {renderTextInput("Actual", "Actual", "managementactual")}
          <div className={"tabforms-spacer"} />
          <p className={"mini-title"}>Trustee Fee</p>
          {renderTextInput("Prospectus", "Prospectus", "trusteeprospectus")}
          <div className={"tabforms-spacer"} />
          {renderTextInput("Actual", "Actual", "trusteeactual")}
          <div className={"tabforms-spacer"} />
          <p className={"mini-title"}>Registration Fee</p>
          {renderTextInput(
            "Prospectus",
            "Prospectus",
            "registrationprospectus"
          )}
          <div className={"tabforms-spacer"} />
          {renderTextInput("Actual", "Actual", "registrationactual")}
        </div>
      </div>
    </Fragment>
  );
}
// end test

export default TabForms;
