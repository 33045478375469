import React from "react";
import Images from "../../utils/styles/images";

const docStatus = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  IN_REVIEW: 4,
};

const accStatus = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  IN_REVIEW: 4,
  CREATING: 5,
  JUST_CONVERT_FROM_EXISTING: 6,
};

export function modifyCell(cell, pageSelect, pageSize, handleActionClick) {
  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowNumber = props && props.row.index + 1;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

  if (columnName === "NO.") {
    if (pageSelect !== 0) {
      return rowNumber + pageSelect * 10;
    } else {
      return rowNumber;
    }
  } else if (
    columnName === "CREATED AT" ||
    columnName === "UPDATED AT"
  ) {
    if (cellValue) {
      let tDate = "";
      let aDate1 = cellValue.split("T");
      let datePart = aDate1[0];
      let timePart = aDate1[1].substring(0, 8);
  
      let dateTime = new Date(`${datePart}T${timePart}Z`); // Treat as UTC
      dateTime.setHours(dateTime.getHours() + 7); // Add 7 hours
  
      let adjustedDate = dateTime.toISOString().split("T")[0];
      let adjustedTime = dateTime.toISOString().split("T")[1].substring(0, 8);
  
      return `${adjustedDate} ${adjustedTime}`;
  }
  } else if (columnName === "EMAIL") {
    return cellValue || "-";
  } else if (columnName === "USERNAME") {
      return cellValue || "-";
  } else if (columnName === "ID No.") {
    return cellValue || "-";
  } else if (columnName === "FULL NAME (EN)") {
    return cellValue || "-";
  } else if (columnName === "FIRST NAME (EN)") {
    return cellValue || "-";
  } else if (columnName === "LAST NAME (EN)") {
    return cellValue || "-";
  } else if (columnName === "FIRST NAME (TH)") {
    return cellValue || "-";
  } else if (columnName === "LAST NAME (TH)") {
    return cellValue || "-";
  } else if (columnName === "FULL NAME (EN-OCR)") {
    return cellValue || "-";
  } else if (columnName === "PREFIX (EN-OCR)") {
    return cellValue || "-";
  } else if (columnName === "FIRST NAME (EN-OCR)") {
    return cellValue || "-";
  } else if (columnName === "LAST NAME (EN-OCR)") {
    return cellValue || "-";
  } else if (columnName === "BIRTHDAY (EN-OCR)") {
    return cellValue || "-";
  } else if (columnName === "FULL NAME (TH-OCR)") {
    return cellValue || "-";
  } else if (columnName === "PREFIX (TH-OCR)") {
    return cellValue || "-";
  } else if (columnName === "FIRST NAME (TH-OCR)") {
    return cellValue || "-";
  } else if (columnName === "LAST NAME (TH-OCR)") {
    return cellValue || "-";
  } else if (columnName === "BIRTHDAY (TH-OCR)") {
    return cellValue || "-";
  } else if (columnName === "FULL NAME (EN) %" || 
    columnName === "PREFIX (EN) %" || 
    columnName === "FIRST NAME (EN) %" || 
    columnName === "LAST NAME (EN) %" || 
    columnName === "BIRTHDAY (EN) %" ||
    columnName === "FULL NAME (TH) %" ||
    columnName === "PREFIX (TH) %" ||
    columnName === "FIRST NAME (TH) %" ||
    columnName === "LAST NAME (TH) %" ||
    columnName === "BIRTHDAY (TH) %" ||
    columnName === "ID Card No %" 
  ) { 
    const cellFloatValue = cellValue ? parseFloat(cellValue) : 0;

    if (cellFloatValue === 100.00) {
      return <span style={{ color: "green" }}>{cellValue}</span>;
    } else if (cellFloatValue > 80.00 && cellFloatValue < 100.00) {
      return <span style={{ color: "rgb(255, 208, 0)" }}>{cellValue}</span>;
    } else if (cellFloatValue <= 80.00 && cellFloatValue > 50.00) {
      return <span style={{ color: "orange" }}>{cellValue}</span>;
    } else if (cellFloatValue <= 50.00) {
      return <span style={{ color: "red" }}>{cellValue}</span>;
    }

    return cellValue || "-";
  } else if ( columnName === "OVERALL %") {
    const cellFloatValue = cellValue ? parseFloat(cellValue) : 0;
    if (cellFloatValue > 90.00) {
      return <span style={{ color: "green" }}>{cellValue}</span>;
    } else if (cellFloatValue <= 90.00 && cellFloatValue > 70.00) {
      return <span style={{ color: "orange" }}>{cellValue}</span>;
    } else if (cellFloatValue <= 70.00 && cellFloatValue >= 0) {
      return <span style={{ color: "red" }}>{cellValue}</span>;
    }
    return cellValue || "-";

  } else {
    return cell;
  }
}

// This is just for the header cells
export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleFltHeader
) {
  if (
    cell === "DOC. STATUS" ||
    cell === "ACC. STATUS" ||
    cell === "CONVERTED USER" ||
    cell === "ACC. METHOD"
  ) {
    return handleFltHeader ? handleFltHeader(cell) : cell;
  } else if (cell === "SUBMISSION DATE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        SUBMISSION DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "UPDATED AT") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        UPDATED AT
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
