export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    textAlign: "left",
    paddingLeft: 10,
    width: 60,
  },
  {
    Header: "USERNAME",
    accessor: "username",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 0,
  },
  {
    Header: "EMAIL",
    accessor: "email",
    disableSortBy: true,
    textAlign: "left",
    width: 220,
    paddingLeft: 20,
  },
  {
    Header: "ID Card No. (OCR)",
    accessor: "identification_number",
    disableSortBy: true,
    textAlign: "left",
    width: 170,
    paddingLeft: 20,
  },
  // {
  //   Header: "FULL NAME (EN-OCR)",
  //   accessor: "full_name_en",
  //   disableSortBy: true,
  //   textAlign: "left",
  //   width: 200,
  //   paddingLeft: 20,
  // },
  {
    Header: "FIRST NAME (EN-OCR)",
    accessor: "name_en",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
  },
  {
    Header: "LAST NAME (EN-OCR)",
    accessor: "last_name_en",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "BIRTHDAY (EN-OCR)",
    accessor: "birthday_en",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
  },
  {
    Header: "FULL NAME (TH-OCR)",
    accessor: "full_name_th",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
  },
  {
    Header: "FIRST NAME (TH-OCR)",
    accessor: "name_th",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
  },
  {
    Header: "LAST NAME (TH-OCR)",
    accessor: "last_name_th",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "BIRTHDAY (TH-OCR)",
    accessor: "birthday_th",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
  },
  {
    Header: "ID Card No.",
    accessor: "cd_identification_no",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  // {
  //   Header: "FULL NAME (EN)",
  //   accessor: "cd_full_name_en",
  //   disableSortBy: true,
  //   textAlign: "left",
  //   width: 200,
  //   paddingLeft: 20,
  //   Cell: ({ row }) => {
  //     const firstName = row.original.cd_first_name || "";
  //     const lastName = row.original.cd_last_name || "";
  //     const title = row.original.cd_title || "";

  //     const titleMapping = {
  //       "01": { TH: "นาย", EN: "Mr." },
  //       "02": { TH: "นาง", EN: "Mrs." },
  //       "03": { TH: "น.ส.", EN: "Miss" },
  //       "99": { TH: "", EN: "" },
  //     };

  //     const titleTH = titleMapping[title]?.TH || "";
  //     const titleEN = titleMapping[title]?.EN || "";
  //     return `${titleEN} ${firstName} ${lastName}`;
  //   },
  // },
  {
    Header: "FIRST NAME (EN)",
    accessor: "cd_first_name",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "LAST NAME (EN)",
    accessor: "cd_last_name",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "BIRTHDAY (EN)",
    accessor: "cd_birthday_en",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
    Cell: ({ row }) => {
      const rawBirthday = row.original.cd_birth_date;
      
      if (!rawBirthday) return "-"; 
  
      const date = new Date(rawBirthday);
  
      if (isNaN(date.getTime())) return "-";
  
      const day = date.getDate();
      const month = date.toLocaleString('en-US', { month: 'short' });
      const year = date.getFullYear();
      
      return `${day} ${month}. ${year}`;
    }
  },
  {
    Header: "FULL NAME (TH)",
    accessor: "cd_full_name_th",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
    Cell: ({ row }) => {
      const firstName = row.original.cd_first_name_th ?? "";
      const lastName = row.original.cd_last_name_th ?? ""; 
      const title = row.original.cd_title ?? "";
      if (!firstName && !lastName && !title) {
        return "-"
      }
      const titleMapping = {
        "01": { TH: "นาย", EN: "Mr." },
        "02": { TH: "น.ส.", EN: "Miss" },
        "03": { TH: "นาง", EN: "Mrs." },
        "99": { TH: "", EN: "" },
      };
    
      const titleTH = titleMapping[title]?.TH ?? "";
    
      return `${titleTH} ${firstName} ${lastName}`.trim();
    },
    
  },
  {
    Header: "FIRST NAME (TH)",
    accessor: "cd_first_name_th",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "LAST NAME (TH)",
    accessor: "cd_last_name_th",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "BIRTHDAY (TH)",
    accessor: "cd_birthday_th",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
    Cell: ({ row }) => {
      const rawBirthday = row.original.cd_birth_date || "";
      if (!rawBirthday) return "-"; 
      const date = new Date(rawBirthday);
      if (isNaN(date.getTime())) return "-";
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const gregorianYear = date.getFullYear();
      const thaiBuddhistYear = gregorianYear + 543;
      const thaiMonths = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
      const thaiMonth = thaiMonths[monthIndex];
      return `${day} ${thaiMonth} ${thaiBuddhistYear}`;
    }
  },
  {
    Header: "ID Card No %",
    accessor: "identification_number_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  // {
  //   Header: "FULL NAME (EN) %",
  //   accessor: "full_name_en_similarity",
  //   disableSortBy: true,
  //   textAlign: "left",
  //   width: 200,
  //   paddingLeft: 20,
  // },
  {
    Header: "FIRST NAME (EN) %",
    accessor: "name_en_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "LAST NAME (EN) %",
    accessor: "last_name_en_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "BIRTHDAY (EN) %",
    accessor: "birthday_en_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "FULL NAME (TH) %",
    accessor: "full_name_th_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 200,
    paddingLeft: 20,
  },
  {
    Header: "FIRST NAME (TH) %",
    accessor: "name_th_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "LAST NAME (TH) %",
    accessor: "last_name_th_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "BIRTHDAY (TH) %",
    accessor: "birthday_th_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "OVERALL %",
    accessor: "overall_similarity",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },
  {
    Header: "UPDATED AT",
    accessor: "updated_at",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 0,
  },

];

