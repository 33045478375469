import { all, call, put as putSaga, select } from "redux-saga/effects";
import { config } from "../../utils/config";
import * as api from "../../utils/api";
import {
  failedBanks, failedCountries, failedDoctypes, failedGenders, failedIncomeSources, failedMaritalStatus, failedMonthlyIncomes, failedOccupations, failedPostcode, failedProvinces, fundCategoriesReceived, receiveBanks, receiveBusinessTypes, receiveCountries, receiveDistricts, receiveDoctypes, receiveGenders, receiveIncomeSources, receiveMaritalStatus, receiveMonthlyIncomes, receiveNationalities, receiveOccupations, receivePostcode, receiveProvinces
} from "../actions";

export function* countriesWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/countries?fields=abbr%2Ccode%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    yield putSaga(receiveCountries(response.data));
  } catch (error) {
    yield putSaga(failedCountries(error));
  }
}

export function* nationalitiesWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/nationalities?fields=abbr%2Ccode%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    yield putSaga(receiveNationalities(response.data));
  } catch (error) {
    console.log('nationalitiesWorkerSaga catch', error);
  }
}

export function* gendersWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/genders?fields=code%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    yield putSaga(receiveGenders(response.data));
  } catch (error) {
    yield putSaga(failedGenders(error));
  }
}

export function* banksWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/allBanks?fields=abbr,code,name,nameEn,nameTh`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    yield putSaga(receiveBanks(response.data));
  } catch (error) {
    
    yield putSaga(failedBanks());
  }
}

export function* businesstypesWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/businessTypes?fields=code,name,nameEn,nameTh`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    yield putSaga(receiveBusinessTypes(response.data));
  } catch (error) {
    yield putSaga(failedDoctypes(error));
  }
}

export function* doctypesWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/documentTypes?fields=code%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    yield putSaga(receiveDoctypes(response.data));
  } catch (error) {
    yield putSaga(failedDoctypes(error));
  }
}

export function* maritalStatusesWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/maritalStatuses?fields=code%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    yield putSaga(receiveMaritalStatus(response.data));
  } catch (error) {
    yield putSaga(failedMaritalStatus(error));
  }
}

export function* requestDistrictsSaga({ payload }) {
  try {
    const { provinceCode, type } = payload;
    const accessToken = yield select((state) => state.auth.accessToken);
    const tGUID = yield select((state) => state.auth.GUID);
    if(provinceCode !== "" && provinceCode != null) {
      const result = yield fetch(
        `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/districts?fields=provinceCode%2Ccode%2Cname&provinceCode=${provinceCode}`,
        {
          method: "GET",  
          headers: {
            "Content-Type": "application/json",
            userid: tGUID,
            id_token: "Bearer " + accessToken,
          },
        }
      );
      const response = yield result.json();
      
      
      yield putSaga(receiveDistricts({ data: response.data, type }));
    }
  } catch (error) {
    console.log('catch requestDistrictsSaga', error);
  }
}

export function* provincesWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/provinces?fields=code%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    yield putSaga(receiveProvinces(response.data));
  } catch (error) {
    yield putSaga(failedProvinces(error));
  }
}

export function* postcodesWorkerSaga(action) {
  try {
    let provinceCode = action.provinceCode;
    let districtCode = action.districtCode;
    if (!provinceCode && action.form) {
      provinceCode = yield select(
        state => state.form[action.form].values.province.code
      );
    }

    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/postCodes?fields=code&provinceCode=${provinceCode}&districtCode=${districtCode}`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();
    if (response.data) {
      yield putSaga(receivePostcode(response.data));
    }
  } catch (error) {
    yield putSaga(failedPostcode(error));
  }
}

export function* occupationsWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/occupations?fields=code%2Cname`,
      { 
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();

    yield putSaga(receiveOccupations(response.data));
  } catch (error) {
    yield putSaga(failedOccupations(error));
  }
}

export function* monthlyIncomesWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/monthlyIncomes?fields=code%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();

    yield putSaga(receiveMonthlyIncomes(response.data));
  } catch (error) {
    yield putSaga(failedMonthlyIncomes(error));
  }
}

export function* incomeSourcesWorkerSaga() {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/sourceOfIncomes?fields=code%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    const response = yield result.json();

    yield putSaga(receiveIncomeSources(response.data));
  } catch (error) {
    yield putSaga(failedIncomeSources(error));
  }
}

export function* fundCategoriesWorkerSaga() {
  try {
    const enCall = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/getUniqueFundCategory`, 
      {
        headers: config().headers
      }
    );

    const thCall = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/getUniqueFundCategory`, 
      {
        headers: config().headersTH
      }
    );

    const [
      {
        data: { data: enData }
      },
      {
        data: { data: thData }
      }
    ] = yield all([enCall, thCall]);

    yield putSaga(
      fundCategoriesReceived({
        en: enData,
        th: thData
      })
    );
  } catch (error) {
    console.log('fundCategoriesWorkerSaga catch', error);
  }
}
