import { put, call, all, takeLatest, select } from "redux-saga/effects";
import { setCurrentCustomerUntHldDetail } from "../../UntHldDetail/redux/actions";
import { selectedObjectUuid } from "../../../components/BreadCrumbs/redux/actions";
import {
  REQOcrLog,
  RESOcrLog,
  FailOcrLog,
  REQManualEmail,
  RESManualEmail,
  FailManualEmail,
  
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

// start test
function buildSearchParams(tSrch, tOpSrch) {
  const params = {
    tEmailSrch: "",
    tUUIDSrch: "",
    tUserSrch: "",
    tNameENSrch: "",
    tNameTHSrch: "",
    tIDNoSrch: "",
    tMobileSrch: "",
  };

  switch (tOpSrch) {
    case "Email":
      params.tEmailSrch = tSrch;
      break;
    case "Username":
      params.tUserSrch = tSrch;
      break;
    case "UUID":
      params.tUUIDSrch = tSrch;
      break;
    case "English Name":
      params.tNameENSrch = tSrch;
      break;
    case "Thai Name":
      params.tNameTHSrch = tSrch;
      break;
    case "ID No. / Passport No.":
      params.tIDNoSrch = tSrch;
      break;
    case "Mobile":
      params.tMobileSrch = tSrch;
      break;
    default:
      break;
  }

  return params;
}

function* fetchOcrLogWorker({ payload }) {
  console.log("fetchOcrLogWorker");
  try {
    const accessToken = yield select((state) => state.auth.accessToken);
    const tGUID = yield select((state) => state.auth.GUID);
    const {
      page,
      tSrch,
      tOpSrch,
      FltDocSta,
      FltAccSta,
      FltConvertUsr,
      SortH,
      status_method,
    } = payload;

    const searchParams = buildSearchParams(tSrch, tOpSrch);

    let tFltDocSta = FltDocSta;
    let tFltAccSta = FltAccSta;
    let tFltConvertUsr = FltConvertUsr;
    let tSortH = SortH;
    // ${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/unitholders?page=${page}
    const request = yield fetch(
      /*  `
      ${process.env.REACT_APP_API_LOCAL}/api/v1/admin-actions/unitholders?page=${page}
      `, */
      `
      ${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/ocrlog?page=${page}
      `,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken,
        },
        body: JSON.stringify({
          ...searchParams,
          FltStaCode: tFltAccSta,
          FltDocStaCode: tFltDocSta,
          FltConVertUsr: tFltConvertUsr,
          sortH: tSortH,
          status_method: status_method,
        }),
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut());
      return;
    }
    const response = yield request.json();
    if (response) {
      yield put(RESOcrLog(response));
    } else {
      yield put(RESOcrLog());
    }
  } catch (error) {
    yield put(FailOcrLog());
  }
}

function* postRequestManualEmail() {
  console.log("fetchOcrLogWorker");
  try {
    const accessToken = yield select((state) => state.auth.accessToken);
    const tGUID = yield select((state) => state.auth.GUID);

    const request = yield fetch(
      `
      ${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/ocr/email/manual
      `,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken,
        },
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut());
      return;
    }
    const response = yield request.json();
    if (response) {
      yield put(RESManualEmail(response));
    } else {
      yield put(RESManualEmail());
    }
  } catch (error) {
    yield put(FailManualEmail());
  }
}
// end test


export function* ocrLogSaga() {
  yield all([
    takeLatest(REQOcrLog, fetchOcrLogWorker),
    takeLatest(REQManualEmail,postRequestManualEmail)
  ]);
}
