import { fork, all, takeLatest, takeEvery, take } from "redux-saga/effects";
import authSaga from "../../containers/Authentication/redux/saga";
import { CHANGE_LOCALE } from "../../containers/LanguageProvider/constants";
import { fundsListSaga } from "../../containers/FundList/redux/saga";

import { fundRecListSaga } from "../../containers/FundRecommendList/redux/saga";

import { fundRecCodiListSaga } from "../../containers/FundRecommendList/FundRecCodi_redux/saga";
import { fundRecCodiDetailSaga } from "../../containers/FundRecommendDetail/FundRecCodi_redux/saga";

import { fundRecRiskListSaga } from "../../containers/FundRecommendList/FundRecRisk_redux/saga";
import { fundRecRiskDetailSaga } from "../../containers/FundRecommendDetail/FundRecRisk_redux/saga";

import { clientsWorkerSaga } from "../../containers/Clients/redux/saga";
import { clientDetailSagas } from "../../containers/ClientDetail/redux/saga";

import { tagListSaga } from "../../containers/TagList/redux/saga";
import { tagDetailSaga } from "../../containers/TagDetail/redux/saga";

import { srchRecListSaga } from "../../containers/SrchRecList/redux/saga";
import { srchRecDetailSaga } from "../../containers/SrchRecDetail/redux/saga";

import { conFdSortListSaga } from "../../containers/ContentFeedSortList/redux/saga";

import { conFdDetailSaga } from "../../containers/ContentFeedDetail/redux/saga";

import { regisDeviceListSaga } from "../../containers/RegisDeviceList/redux/saga";

import { actiListSaga } from "../../containers/ActiList/redux/saga";

import { cusServiceListSaga } from "../../containers/CusServiceList/redux/saga";

import { usrActListSaga } from "../../containers/UsrActList/redux/saga";
import { usrActDetailSaga } from "../../containers/UsrActDetail/redux/saga";

import { impRGTtoB2CListSaga } from "../../containers/ImpRGTtoB2CList/redux/saga";

import { untHldListSaga } from "../../containers/UntHldList/redux/saga";
import { untHldDetailSaga } from "../../containers/UntHldDetail/redux/saga";
import { ocrLogSaga } from "../../containers/OcrLog/redux/saga";

import { untHldListAutoSaga } from "../../containers/AutoApprove/redux/saga";
import { autoApproveDetailSaga } from "../../containers/AutoApproveDetail/redux/saga";
import { regisLogListSaga } from "../../containers/RegisLogList/redux/saga";
import { regisLogDetailSaga } from "../../containers/RegisLogDetail/redux/saga";

import { consentListSaga } from "../../containers/ConsentList/redux/saga";
import { consentVSListSaga } from "../../containers/ConsentVSList/redux/saga";

import { analyticListSaga } from "../../containers/AnalyticList/redux/saga";
import { analyticDetailSaga } from "../../containers/AnalyticDetail/redux/saga";

import { fundDetailsSaga } from "../../containers/FundDetail/redux/saga";

import { transactionsListSaga } from "../../containers/TransactionDetails/redux/saga";
import { atsListSaga } from "../../containers/RequestATSDetails/redux/saga";
import { rspListSaga } from "../../containers/RSPAccountDetails/redux/saga";
import { forgotPasswordSagas } from "../../containers/ForgotPassword/redux/saga";
import { resetPasswordSagas } from "../../containers/LetsSetupYourAccount/redux/saga";
import { contentFeedListSagas } from "../../containers/ContentFeedList/redux/saga";

import { REHYDRATE } from "redux-persist/lib/constants";
import {
  REQUEST_DROPDOWN_DATA,
  REQUEST_DISTRICTS
} from "../../globalRedux/constants";
import {
  countriesWorkerSaga,
  banksWorkerSaga,
  doctypesWorkerSaga,
  gendersWorkerSaga,
  incomeSourcesWorkerSaga,
  nationalitiesWorkerSaga,
  provincesWorkerSaga,
  maritalStatusesWorkerSaga,
  occupationsWorkerSaga,
  monthlyIncomesWorkerSaga,
  fundCategoriesWorkerSaga,
  requestDistrictsSaga,
  businesstypesWorkerSaga
} from "../../globalRedux/saga";

export function* onSelectDataLoading() {
  yield all([
    countriesWorkerSaga(),
    gendersWorkerSaga(),
    doctypesWorkerSaga(),
    maritalStatusesWorkerSaga(),
    provincesWorkerSaga(),
    occupationsWorkerSaga(),
    monthlyIncomesWorkerSaga(),
    incomeSourcesWorkerSaga(),
    nationalitiesWorkerSaga(),
    banksWorkerSaga(),
    fundCategoriesWorkerSaga(),
    businesstypesWorkerSaga()
  ]);
}

export function* loadDataWithNewLanguageSaga() {
  yield all([onSelectDataLoading()]);
}

export default function* rootSaga() {
  // Don't run sagas yet until redux-persist has reloaded everything into store
  // useful for when opening website again in a previously closed browser.
  // This should prevent sagas from trying to run with empty auth store.
  yield take(REHYDRATE);

  yield all([
    takeLatest(CHANGE_LOCALE, loadDataWithNewLanguageSaga),
    takeEvery(REQUEST_DISTRICTS, requestDistrictsSaga),
    takeLatest(REQUEST_DROPDOWN_DATA, onSelectDataLoading),
    atsListSaga(),
    rspListSaga(),
    transactionsListSaga(),
    forgotPasswordSagas(),
    resetPasswordSagas(),
    fork(authSaga),
    clientsWorkerSaga(),
    clientDetailSagas(),
    fundsListSaga(),

    fundRecListSaga(),

    fundRecCodiListSaga(),
    fundRecCodiDetailSaga(),

    fundRecRiskListSaga(),
    fundRecRiskDetailSaga(),

    tagListSaga(),
    tagDetailSaga(),

    srchRecListSaga(),
    srchRecDetailSaga(),

    conFdSortListSaga(),

    conFdDetailSaga(),

    regisDeviceListSaga(),
    actiListSaga(),

    fundDetailsSaga(),
    contentFeedListSagas(),

    clientsWorkerSaga(),

    cusServiceListSaga(),

    usrActListSaga(),
    usrActDetailSaga(),

    untHldListSaga(),
    untHldDetailSaga(),
    ocrLogSaga(),
    
    untHldListAutoSaga(),
    autoApproveDetailSaga(),
    
    impRGTtoB2CListSaga(),

    regisLogListSaga(),
    regisLogDetailSaga(),

    consentListSaga(),
    consentVSListSaga(),

    analyticListSaga(),
    analyticDetailSaga()
  ]);
}
