import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

export const MenuList = ({
  activeIndex,
  onHandleClick,
  Images,
  menuListArray
}) => {
  return menuListArray.map((item) => {
    const getClassNames = () => {
      let classNames = "";
      const nestedMappings = {
        11: [115, 116, 117, 118, 119],
        12: [121, 122, 123],
        3: [31, 32, 33],
        6: [61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 70],
        15: [151, 152],
        16: [161, 162, 163],
        18: [181, 182]
      };

      const nestedIndices = nestedMappings[item.id];

      if (nestedIndices && nestedIndices.includes(activeIndex)) {
        classNames += " transaction_link active";
      }

      if (item.id === activeIndex) {
        classNames += " active";
      }

      return classNames.trim();
    };

    const showTransactionDetails = (id) => {
      const idMappings = {
        11: [115, 116, 117,118, 119],
        3: [31, 32, 33],
        6: [62, 63, 64, 65, 66, 67, 68, 69, 71, 70],
        12: [121, 122, 123],
        15: [151, 152],
        16: [161, 162, 163],
        18: [181, 182]
      };

      return idMappings[id] ? idMappings[id].includes(activeIndex) : false;
    };

    return (
      <Link
        to={item.link}
        key={item.id}
        onClick={onHandleClick(item.id, item.link)}
        className={getClassNames()}
      >
        <div className="menu-item-title">
          <img src={item.icon} alt="Icon" />
          <FormattedMessage {...messages[item.title]} />

          {item.text === "Transaction" && (
            <img
              className="arrow"
              src={Images.arrowDownWhite.arrow}
              alt="Icon"
            />
          )}
          {item.text === "Clients" && (
            <img
              className="arrow"
              src={Images.arrowDownWhite.arrow}
              alt="Icon"
            />
          )}
          {item.text === "Fund Recommend" && (
            <img
              className="arrow"
              src={Images.arrowDownWhite.arrow}
              alt="Icon"
            />
          )}
          {item.text === "Content Feed" && (
            <img
              className="arrow"
              src={Images.arrowDownWhite.arrow}
              alt="Icon"
            />
          )}
          {item.text === "Activity Log" && (
            <img
              className="arrow"
              src={Images.arrowDownWhite.arrow}
              alt="Icon"
            />
          )}
          {item.text === "Register Log" && (
            <img
              className="arrow"
              src={Images.arrowDownWhite.arrow}
              alt="Icon"
            />
          )}
          {item.text === "Analytic" && (
            <img
              className="arrow"
              src={Images.arrowDownWhite.arrow}
              alt="Icon"
            />
          )}
        </div>

        {item.children && showTransactionDetails(item.id) && (
          <ul className="nested-list">
            {item.children.map((child) => {
              return (
                <li
                  onClick={onHandleClick(child.id, child.link)}
                  key={child.id}
                  className={activeIndex === child.id ? "active" : ""}
                >
                  <span className="nested-list-span">{child.text}</span>
                </li>
              );
            })}
          </ul>
        )}
      </Link>
    );
  });
};

MenuList.propTypes = {
  menuListArray: PropTypes.array.isRequired,
  onHandleClick: PropTypes.func.isRequired,
  Images: PropTypes.object.isRequired
};
