import React, { Component } from "react";
import "./style.scss";
import { aConfigUrl } from "../../utils/ConfigUrl";
class DefaultScreen extends Component {
  componentWillUnmount() {
    // Clear timeout to prevent memory leaks
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundImage: `url(${
            require("../../assets/images/bgImage/rectangle.svg").default
          })`,
          width: "100%",
          height: "100vh",
          maxWidth: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <span className="txtVersion">
          © 2024 All Rights Reserved. {aConfigUrl().TextPhase}{" "}
          {aConfigUrl().Version}
        </span>
      </div>
    );
  }
}

export default DefaultScreen;
