import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { signIn } from "../../globalRedux/constants/loader";
import { aConfigUrl } from "../../utils/ConfigUrl";
import { validate } from "../../utils/functions/login_form_validation";
import { resetFailedRequestProps } from "./../../containers/Authentication/redux/actions";
import { signInAction } from "./redux/actions";
import "./style.scss";
import { OktaAuth } from "@okta/okta-auth-js";

class LogoutScreen extends Component {
  state = {
    auth: false,
    errorStateMessage: false,
    stSHWAlrOktaStatus: true,
    name: ""
  };

  componentDidMount() {
    // Redirect to login page after 5 seconds
    this.redirectTimeout = setTimeout(() => {
      this.props.history.push("/login");
    }, 3000);
  }

  componentWillUnmount() {
    // Clear timeout to prevent memory leaks
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }
  }

  static propTypes = {
    signIn: PropTypes.func,
    history: PropTypes.object.isRequired // Added for withRouter
  };

  static defaultProps = {
    errorString: "Timeout.",
    form: { signin: { syncErrors: {} } },
    stSHWAlrOktaStatus: true
  };

  static getDerivedStateFromProps(props, state) {
    const {
      auth: { errorMessage },
      form: { signin = {} }
    } = props;

    const isActive = signin && signin.active;

    if (errorMessage && isActive && state.errorStateMessage) {
      props.resetFailedRequestProps();
      return { errorStateMessage: false };
    }

    if (!errorMessage) {
      return { errorStateMessage: false };
    }

    return { errorStateMessage: true };
  }

  render() {
    return (
      <div className="wrapper">
        <div className="imageBackgroundWrap" />
        <div className="formWrap">
          <div className="formContainer">
            <div className="formName">
              <h2>You have successfully logged out.</h2>
            </div>
          </div>
          <div className="container-reset-password">
            <p className="Cant-access-your-ac">
              You will be redirected to the login page shortly.
            </p>
          </div>
          <div className="copyright">
            &#169; 2024 All Rights Reserved. {aConfigUrl().TextPhase}{" "}
            {aConfigUrl().Version}
          </div>
        </div>
      </div>
    );
  }
}

const ReduxForm = reduxForm({
  form: "signin",
  validate
});

const withConnect = connect(
  state => ({
    auth: state.auth,
    clients: state.clientReducer,
    globalProps: state.globalReducer,
    form: state.form,
    loading: state.loaderReducer[signIn]
  }),
  { signIn: signInAction, resetFailedRequestProps }
);

export default compose(
  ReduxForm,
  withConnect,
  withRouter
)(LogoutScreen);
